import React, { useState } from "react";
import { Badge } from "react-bootstrap";

import "./book-form-notes.style.scss";

const BookFormNotes = ({ formDetails }) => {
  const [noteDetails, setNoteDetails] = useState("");
  const handleBadgeClick = (note) => {
    if (noteDetails === "") {
      setNoteDetails(note);
    } else {
      setNoteDetails("");
    }
  };

  return (
    <div className="form-row book-form-notes">
      <div className="form-group col-12">
        <span className="f-14">Additional Information:</span>
        <div className="row mx-0 mt-2">
          {formDetails.notes
            .filter((item) => item.important === 0)
            .map((item, i) => (
              <Badge
                key={i}
                variant="warning"
                className="f-1 link mr-3 mb-2 badge"
                onClick={() => handleBadgeClick(item.body)}
              >
                {item.title}
              </Badge>
            ))}
        </div>
        {noteDetails !== "" && (
          <div
            className="row mx-0 mt-2 f-8 notes"
            dangerouslySetInnerHTML={{ __html: noteDetails }}
          />
        )}
      </div>
    </div>
  );
};

export default BookFormNotes;
