import React from "react";
import { Skeleton } from "@material-ui/lab";

import SkeletonContainerModes from "./skeleton-container.modes";
import CardSkeleton from "../skeletons/card-skeleton.component";
import SectionSkeleton from "../skeletons/section-skeleton.component";

const SkeletonContainer = (WrappedComponent) => ({
  isLoading,
  skeletonRows = 1,
  mode = SkeletonContainerModes.TEXT_MODE,
  ...props
}) => {
  switch (isLoading) {
    case true:
      switch (mode) {
        case SkeletonContainerModes.SECTION_MODE:
          return <SectionSkeleton />;
        case SkeletonContainerModes.CARD_MODE:
          return [...Array(skeletonRows)].map((e, i) => <CardSkeleton key={i} />);
        case SkeletonContainerModes.TEXT_MODE:
          return [...Array(skeletonRows)].map((e, i) => <Skeleton key={i} />);
        default:
      }
      break;
    default:
      return <WrappedComponent {...props} />;
  }
};

export default SkeletonContainer;
