import React from "react";

const InputSelect = ({
  onChange,
  className,
  value,
  placeholder,
  title,
  name,
  required = true,
  type = "text",
  options,
}) => {
  return (
    <div className="form-group col-12 col-lg-6">
      <div className="mb-20 mb-lg-0 input_block">
        <div className="mb-10 f-14 semibold text-uppercase sp-20">{title}</div>
        <select
          required={required}
          type={type}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          className={`input w-full bg-white border-action-2 focus-action-2 color-heading placeholder-heading ${className}`}
          value={value}
        >
          <option value="">Select {title}</option>
          {options.map((item, i) => (
            <option key={i} value={item.value}>
              {item.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default InputSelect;
