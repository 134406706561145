import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectModules } from "../../redux/module/module.selector";

import "./module-card.style.scss";
import { Link } from "react-router-dom";

const ModuleCard = ({ modules }) => {
  return modules.map((module, index) => (
    <div
      key={index}
      className="col-xl-4 col-lg-5 col-md-6 mt-10 module-card"
      data-aos-duration="600"
      data-aos="fade-down"
      data-aos-delay="0"
    >
      <Link
        to={module.url}
        className="h-full link color-white d-flex justify-content-end"
      >
        <div className="module-image">
          <img src={require("../../assets/i/" + module.image)} alt="" />
        </div>
        <div className="w-170 mw-270 f-22 medium text-adaptive d-flex align-items-center justify-content-center card-title">
          {module.name}
        </div>
        <div className="bg-image">
          <img src={require("../../assets/i/card-bg.png")} alt="" />
        </div>
      </Link>
    </div>
  ));
};

const mapStateToProps = createStructuredSelector({
  modules: selectModules,
});

export default connect(mapStateToProps)(ModuleCard);
