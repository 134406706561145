import React, { useEffect } from "react";
import { connect } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { createStructuredSelector } from "reselect";
import { Route, Switch } from "react-router-dom";

import { selectModules } from "./redux/module/module.selector";
import { fetchAboutStartAsync } from "./redux/about/about.action";
import { fetchEventRoomTypeStartAsync } from "./redux/event-room/types/event-room-type.action";
import { fetchEventRoomPriceStartAsync } from "./redux/event-room/prices/event-room-price.action";
import { fetchVirtualOfficeStartAsync } from "./redux/virtual-office/virtual-office.action";

import IndexPage from "./pages/index/index-page.components";
import VirtualOfficePage from "./pages/virtual-office/virtual-office-page.component";
import CorporateSecretarialPage from "./pages/corporate-secretarial/corporate-secretarial-page.component";

import MainLayout from "./components/main-layout/main-layout.component";

import "./style.scss";
import { fetchCorpSecretaryStartAsync } from "./redux/corp-secretary/corp-secretary.action";
import PaymentLoading from "./components/payment-loading/payment-loading.component";
import ThankYouPage from "./pages/thank-you/thank-you.component";
import SecondaryLayout from "./components/secondary-layout/secondary-layout.component";
import AdditionalServiceForm from "./components/additional-service-form/additional-service-form.component";

function App({
  modules,
  fetchAbout,
  fetchEventRoomType,
  fetchEventRoomPrice,
  fetchVirtualOffice,
  fetchCorpSecretary,
}) {
  useEffect(() => {
    fetchAbout();
    fetchEventRoomType();
    fetchEventRoomPrice();
    fetchVirtualOffice();
    fetchCorpSecretary();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const componentList = {
    Index: IndexPage,
    VirtualOffice: VirtualOfficePage,
    CorpSecretarial: CorporateSecretarialPage,
  };
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

  return (
    <Switch>
      <Elements stripe={stripePromise}>
        <Route exact path={modules.map((module) => module.url)}>
          <MainLayout>
            {modules.map((module, index) => (
              <Route
                key={index}
                exact
                path={module.url}
                render={() => {
                  const ModuleComponent = componentList[module.component];
                  return <ModuleComponent />;
                }}
              ></Route>
            ))}
          </MainLayout>
        </Route>
        <Route path="/bas/:type/:inv">
          <SecondaryLayout>
            <AdditionalServiceForm />
          </SecondaryLayout>
        </Route>
        <Route exact path="/payment-loading" component={PaymentLoading}></Route>
        <Route exact path="/thank-you" component={ThankYouPage}></Route>
        {
          // <Route exact path="/test" component={SelectionDropdown}></Route>
        }
      </Elements>
    </Switch>
  );
}

const mapStateToProps = createStructuredSelector({
  modules: selectModules,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAbout: () => dispatch(fetchAboutStartAsync()),
  fetchEventRoomType: () => dispatch(fetchEventRoomTypeStartAsync()),
  fetchEventRoomPrice: () => dispatch(fetchEventRoomPriceStartAsync()),
  fetchVirtualOffice: () => dispatch(fetchVirtualOfficeStartAsync()),
  fetchCorpSecretary: () => dispatch(fetchCorpSecretaryStartAsync()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
