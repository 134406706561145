import ADDITIONAL_SERVICE_FORM_TYPES from "./additional-service-form.types";
import { addFieldData } from "./additional-service-form.actions.utils";

const INITIAL_STATE = {
  fields: [],
  fetching: false,
  errorMessage: undefined,
  dataToSubmit: [],
};

const additionalServiceFormReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADDITIONAL_SERVICE_FORM_TYPES.FETCH_START:
      return {
        ...state,
        fetching: true,
      };
    case ADDITIONAL_SERVICE_FORM_TYPES.FETCH_STOP:
      return {
        ...state,
        fetching: false,
      };
    case ADDITIONAL_SERVICE_FORM_TYPES.FETCH_SUCCESS:
      return {
        ...state,
        fetching: false,
        fields: action.payload.data,
        invoiceNumber: action.payload.invoiceNumber,
      };
    case ADDITIONAL_SERVICE_FORM_TYPES.FETCH_ERROR:
      return {
        ...state,
        fetching: false,
        errorMessage: action.payload,
      };
    case ADDITIONAL_SERVICE_FORM_TYPES.UPDATE_FIELD:
      return {
        ...state,
        dataToSubmit: addFieldData(action.payload, state.dataToSubmit),
      };
    case ADDITIONAL_SERVICE_FORM_TYPES.SET_FIELD:
      return {
        ...state,
        dataToSubmit: action.payload,
      };
    default:
      return state;
  }
};

export default additionalServiceFormReducer;
