import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectEventRoomPrices } from "../../redux/event-room/event-room.selector";

import EventRoomPricingCard from "../event-room-pricing-card/event-room-pricing-card.component";

const EventRoomPrices = ({ pricing }) => {
  const [weekendMode, setWeekendMode] = useState(false);
  const toggleWeekendMode = () => {
    setWeekendMode(!weekendMode);
  };
  return (
    <Fragment>
      <div
        className="d-flex align-items-center justify-content-center slider_menu"
        data-aos-duration="600"
        data-aos="fade-down"
        data-aos-delay="600"
      >
        <div className="mr-30 font-weight-bold" style={{ color: "#000E7E"}}>Weekdays</div>
        <div className="custom-control custom-switch">
          <input
            type="checkbox"
            className="custom-control-input"
            id="switch1"
            checked={weekendMode}
            onChange={toggleWeekendMode}
          />
          <label className="custom-control-label" htmlFor="switch1"></label>
        </div>
        <div className="ml-15 font-weight-bold" style={{ color: "#000E7E"}}>Weekend</div>
      </div>
      <div className="mt-60 slider">
        <div>
          <div className="row justify-content-center">
            {pricing.map((price, index) => (
              <EventRoomPricingCard
                key={index}
                pricing_data={price}
                price={
                  !weekendMode ? price.price_weekdays : price.price_weekend
                }
              />
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = createStructuredSelector({
  pricing: selectEventRoomPrices,
});

export default connect(mapStateToProps)(EventRoomPrices);
