import React, { Fragment, useState, useEffect } from "react";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import AddIcon from "@material-ui/icons/Add";
import { Badge, Accordion, Card, Spinner } from "react-bootstrap";
import NavigateNextRoundedIcon from "@material-ui/icons/NavigateNextRounded";
import NavigateBeforeRoundedIcon from "@material-ui/icons/NavigateBeforeRounded";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";

import useGlobalState from "../../global-hook/global-hook-state";
import { selectEventRoomPrices } from "../../redux/event-room/event-room.selector";
import { selectPaymentTerminalLoadingStatus } from "../../redux/payment-terminal/payment-terminal.selector";

import { checkAvailability, saveBookingData } from "./book-form.utils";
import { makeEventSpaceFreePayment } from "../../helpers/payment.helper";

import DateSelector from "../date-selector/date-selector.component";
import BookingSummary from "../booking-summary/booking-summary.component";

import PaymentTerminal from "../payment-teriminal/payment-teriminal.component";

import "./event-space-book-form.style.scss";

const EventSpaceBookForm = ({ formDetails, roomRates, paymentTerminalLoading }) => {
  const initRooms = {
    key: Date.now(),
    beginDate: "",
    endDate: "",
    timeId: "",
    pax: "",
    usage: "",
    consecutive: false,
  };

  //eslint-disable-next-line no-unused-vars
  const [modalShow, setModalShow] = useGlobalState("showBookModal");
  const [noteDetails, setNoteDetails] = useState("");
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    rooms: [initRooms],
  });
  const [errMsg, setErrMsg] = useState("");
  //Times for Rate
  const [rateTimes, setRateTimes] = useState([]);
  //User Agreement
  const [agreement, setAgreement] = useState(false);
  const [individualDateError, setIndividualDateError] = useState([]);
  const [nextLoading, setNextLoading] = useState(false);
  //saved booking data
  const [savedBookingData, setSavedBookingData] = useState([]);

  const handleBadgeClick = (note) => {
    setErrMsg("");
    setNoteDetails(note);
  };

  const handleModalClose = () => setModalShow(false);

  const handleFormData = (e) => {
    setErrMsg("");
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handlePrevious = () => {
    setErrMsg("");
    setNoteDetails("");
    setStep(step - 1);
  };

  const handleNext = async () => {
    setErrMsg("");
    setNoteDetails("");
    switch (step) {
      case 1:
        setNextLoading(true);
        const roomData = formData.rooms;
        let enableFetch = true;
        roomData.forEach(async (room) => {
          if (
            room.beginDate === "" ||
            (room.consecutive === true && room.endDate === "") ||
            room.timeId === "" ||
            room.pax === "" ||
            room.pax === 0 ||
            room.usage === ""
          ) {
            setErrMsg("Please Fill All Required Fields");
            enableFetch = false;
          }
        });
        if (enableFetch) {
          const checkAvailable = await checkAvailability(formData.rooms);
          if (checkAvailable.success) {
            setStep(step + 1);
          } else {
            setErrMsg(checkAvailable.message);
            setIndividualDateError(checkAvailable.errorDetails);
          }
        }
        setNextLoading(false);

        break;
      case 2:
        if (!formData.name || !formData.contact || !formData.email || !formData.billingAddress) {
          setErrMsg("Please Fill All Required Fields");
          break;
        } else {
          setStep(step + 1);
          break;
        }
      case 3:
        setNextLoading(true);
        if (agreement) {
          const saveBookData = await saveBookingData(formData);
          if (saveBookData.success) {
            setStep(step + 1);
            setNextLoading(false);
            setSavedBookingData(saveBookData.data);
            break;
          } else {
            setErrMsg(saveBookData.errorMessage);
            setNextLoading(false);
            break;
          }
        } else {
          setErrMsg("Please tick the agreement before proceeding");
          setNextLoading(false);
          break;
        }
      case 4:
        if (savedBookingData.discount_type === "free") {
          setNextLoading(true);
          const res = await makeEventSpaceFreePayment(savedBookingData.invoice_number);
          if (res.success) {
            window.location.href = `${process.env.REACT_APP_BASE_URL}thank-you`;
            break;
          } else {
            setErrMsg(res);
            break;
          }
        }
        setStep(step + 1);
        break;
      default:
    }
  };

  const handleBeginDate = (key, date) => {
    formData.rooms[key].beginDate = date;
    if (formData.rooms[key].consecutive === false) {
      formData.rooms[key].endDate = date;
    }
    setFormData({ ...formData });
  };

  const handleEndDate = (key, date) => {
    formData.rooms[key].endDate = date;
    setFormData({ ...formData });
  };

  const handleRoomForm = (e, key) => {
    setErrMsg("");
    let { name, value } = e.target;
    if (name === "consecutive") {
      value = e.target.checked;
      if (value === false) {
        formData.rooms[key].endDate = formData.rooms[key].beginDate;
      }
    } else if (name === "timeId") {
      let rateId = rateTimes.find((item) => item.id === Number(value));
      rateId = rateId.idRate;
      formData.rooms[key].idRate = rateId;
      setFormData({ ...formData });
    }
    const roomData = formData.rooms[key];
    const insertRoomData = { ...roomData, [name]: value };
    formData.rooms[key] = insertRoomData;
    setFormData({ ...formData });
  };

  const addRoom = () => {
    formData.rooms.push({ ...initRooms, key: Date.now() });
    setFormData({ ...formData });
  };

  const handleDeleteBooking = (key) => {
    const filteredFormDataRooms = formData.rooms.filter((room) => room.key !== key);
    setFormData({ ...formData, rooms: filteredFormDataRooms });
  };

  const importantNotes = formDetails.notes.filter((item) => item.important === 1);

  useEffect(() => {
    let tempArr = [];
    roomRates.forEach((item) => {
      const times = item.times;
      if (times.length > 0) {
        times.map((items) => tempArr.push({ ...items, name: item.title, idRate: item.id }));
      }
    });
    setRateTimes(tempArr);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="event-space-book-form">
      {(() => {
        switch (step) {
          case 1:
            return (
              <Fragment>
                <form>
                  {formData.rooms.map((room, k) => (
                    <DateSelector
                      id={k}
                      key={k}
                      handleBeginDate={handleBeginDate}
                      handleEndDate={handleEndDate}
                      handleRoomForm={handleRoomForm}
                      roomProp={room}
                      totalBookProp={formData.rooms.length}
                      handleDeleteBooking={handleDeleteBooking}
                      rateTimes={rateTimes}
                      individualDateError={individualDateError}
                    />
                  ))}
                </form>
                <div className="row d-flex justify-content-center align-items-center mt-2">
                  <span className="d-flex link" onClick={addRoom}>
                    <AddIcon className="action-2" /> Add More
                  </span>
                </div>
              </Fragment>
            );
          case 2:
            return (
              <Fragment>
                <div className="form-row">
                  <div className="form-group col-12 col-lg-6">
                    <div className="mb-20 mb-lg-0 input_block">
                      <div className="mb-10 f-14 semibold text-uppercase sp-20">Name</div>
                      <input
                        required
                        type="text"
                        name="name"
                        onChange={handleFormData}
                        value={!formData.name ? "" : formData.name}
                        placeholder="Your Name"
                        className="input w-full bg-white border-action-2 focus-action-2 color-heading placeholder-heading"
                      />
                    </div>
                  </div>
                  <div className="form-group col-12 col-lg-6">
                    <div className="mb-20 mb-lg-0 input_block">
                      <div className="mb-10 f-14 semibold text-uppercase sp-20">Email</div>
                      <input
                        required
                        type="email"
                        name="email"
                        value={!formData.email ? "" : formData.email}
                        onChange={handleFormData}
                        placeholder="Your Email"
                        className="input w-full bg-white border-action-2 focus-action-2 color-heading placeholder-heading"
                      />
                    </div>
                  </div>
                  <div className="form-group col-12 col-lg-6">
                    <div className="mb-20 mb-lg-0 input_block">
                      <div className="mb-10 f-14 semibold text-uppercase sp-20">
                        Company (If Any)
                      </div>
                      <input
                        required
                        type="text"
                        name="company"
                        value={!formData.company ? "" : formData.company}
                        onChange={handleFormData}
                        placeholder="Your Company Name"
                        className="input w-full bg-white border-action-2 focus-action-2 color-heading placeholder-heading"
                      />
                    </div>
                  </div>
                  <div className="form-group col-12 col-lg-6">
                    <div className="mb-20 mb-lg-0 input_block">
                      <div className="mb-10 f-14 semibold text-uppercase sp-20">Contact Number</div>
                      <input
                        required
                        type="text"
                        name="contact"
                        value={!formData.contact ? "" : formData.contact}
                        onChange={handleFormData}
                        placeholder="Your Phone Number"
                        className="input w-full bg-white border-action-2 focus-action-2 color-heading placeholder-heading"
                      />
                    </div>
                  </div>
                  <div className="form-group col-12">
                    <div className="mb-20 mb-lg-0 input_block">
                      <div className="mb-10 f-14 semibold text-uppercase sp-20">
                        Billing Address
                      </div>
                      <input
                        required
                        type="text"
                        name="billingAddress"
                        value={!formData.billingAddress ? "" : formData.billingAddress}
                        onChange={handleFormData}
                        placeholder="Your Billing Address"
                        className="input w-full bg-white border-action-2 focus-action-2 color-heading placeholder-heading"
                      />
                    </div>
                  </div>
                </div>
              </Fragment>
            );
          case 3:
            return (
              <Fragment>
                <div className="row mx-0 mb-50">
                  <span className="mb-2">
                    <strong>Important Information</strong>
                  </span>
                  <Accordion className="w-full">
                    {formDetails.notes
                      .filter((item) => item.important === 1)
                      .map((item, i) => (
                        <Card key={i}>
                          <Card.Header>
                            <Accordion.Toggle eventKey={item.id}>
                              <KeyboardArrowDownRoundedIcon /> {item.title}
                            </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey={item.id}>
                            <Card.Body
                              className="f-12"
                              dangerouslySetInnerHTML={{ __html: item.body }}
                            />
                          </Accordion.Collapse>
                        </Card>
                      ))}
                  </Accordion>
                </div>
                <div className="form-row">
                  <div className="form-group col-12">
                    <div className="custom-control custom-checkbox">
                      <div>
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="agree"
                          name="consecutive"
                          checked={agreement}
                          onChange={(e) => setAgreement(e.target.checked)}
                        />
                        <label className="custom-control-label" htmlFor="agree">
                          I had read the{" "}
                          {importantNotes.map((item, i) => {
                            let comma;
                            if (i === importantNotes.length - 1) {
                              comma = "";
                            } else if (i === importantNotes.length - 2) {
                              comma = ", and ";
                            } else {
                              comma = ", ";
                            }
                            return item.title + comma;
                          })}
                          . I confirmed my agreement to the requirements expected of me as the user
                          of the space.
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-12 col-lg-6">
                    <div className="mb-10 f-14 semibold text-uppercase sp-20">
                      Promo Code (Optional)
                    </div>
                    <input
                      required
                      type="text"
                      name="promoCode"
                      value={!formData.promoCode ? "" : formData.promoCode}
                      onChange={handleFormData}
                      placeholder="Input Your Promo Code Here"
                      className="input w-full bg-white border-action-2 focus-action-2 color-heading placeholder-heading"
                    />
                  </div>
                </div>
              </Fragment>
            );
          case 4:
            return <BookingSummary formData={savedBookingData} />;
          case 5:
            return <PaymentTerminal bookingData={savedBookingData} />;
          default:
        }
      })()}
      <div className="form-row d-flex align-items-center justify-content-center text-danger mt-3 mb-25">
        {errMsg}
      </div>
      <div className="form-row flex-column-reverse flex-lg-row d-flex">
        <div className="form-group col-12 col-lg-4 mb-2">
          <button
            onClick={handleModalClose}
            className="btn w-full text-danger px-2 font-weight-bold"
            disabled={paymentTerminalLoading}
          >
            Cancel
          </button>
        </div>
        <div className="form-group col-12 col-lg-4 mb-2">
          <button
            disabled={step === 1 || paymentTerminalLoading}
            onClick={handlePrevious}
            className="btn w-full action-2 px-2 font-weight-bold"
          >
            <NavigateBeforeRoundedIcon />
            Previous
          </button>
        </div>
        <div className="form-group col-12 col-lg-4 mb-2">
          {step === 4 ? (
            <button onClick={handleNext} className="btn w-full action-1 px-2 font-weight-bold" style={{ color: '#000E7E'}}>
              {!savedBookingData.discount_type === "free"
                ? "Proceed to Payment"
                : "Finalize Booking"}
            </button>
          ) : (
            step < 4 && (
              <button
                onClick={handleNext}
                disabled={nextLoading || paymentTerminalLoading}
                className="btn w-full action-2 px-2 font-weight-bold d-flex align-items-center justify-content-center"
              >
                Next
                {nextLoading ? (
                  <Spinner size="sm" animation="border" className="ml-3" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                ) : (
                  <NavigateNextRoundedIcon className="ml-3" />
                )}
              </button>
            )
          )}
        </div>
      </div>
      {step > 1 && step < 4 && (
        <div className="form-row">
          <div className="form-group col-12">
            <span className="f-14">Additional Information:</span>
            <div className="row mx-0 mt-2">
              {formDetails.notes
                .filter((item) => item.important === 0)
                .map((item, i) => (
                  <Badge
                    key={i}
                    variant="primary"
                    className="f-14 link mr-3 mb-2 yellow-badge"
                    onClick={() => handleBadgeClick(item.body)}
                  >
                    {item.title}
                  </Badge>
                ))}
            </div>
            {noteDetails !== "" && (
              <div
                className="row mx-0 mt-2 f-8 notes"
                dangerouslySetInnerHTML={{ __html: noteDetails }}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  roomRates: selectEventRoomPrices,
  paymentTerminalLoading: selectPaymentTerminalLoadingStatus,
});

export default connect(mapStateToProps)(EventSpaceBookForm);
