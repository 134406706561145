import { createSelector } from "reselect";

const selectPaymentTerminalState = (state) => state.paymentTerminal;

export const selectPaymentTerminalMode = createSelector(
  [selectPaymentTerminalState],
  (data) => data.mode
);

export const selectPaymentTerminalLoadingStatus = createSelector(
  [selectPaymentTerminalState],
  (data) => data.loading
);
