import EventRoomTypeTypes from "./types/event-room-type.types";
import EventRoomPriceTypes from "./prices/event-room-price.types";

const INITIAL_STATE = {
  typesData: [],
  isTypesFetching: true,
  typesErrorMsg: undefined,
  pricesData: [],
  isPricesFetching: true,
  pricesErrorMsg: undefined,
};

const eventRoomReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EventRoomTypeTypes.FETCH_EVENT_ROOM_TYPE_START:
      return {
        ...state,
        isTypesFetching: true,
      };
    case EventRoomTypeTypes.FETCH_EVENT_ROOM_TYPE_SUCCESS:
      return {
        ...state,
        isTypesFetching: false,
        typesData: action.payload,
      };
    case EventRoomTypeTypes.FETCH_EVENT_ROOM_TYPE_FAIL:
      return {
        ...state,
        isTypesFetching: false,
        typesErrorMsg: action.payload,
      };
    case EventRoomPriceTypes.FETCH_EVENT_ROOM_PRICE_START:
      return {
        ...state,
        isPricesFetching: true,
      };
    case EventRoomPriceTypes.FETCH_EVENT_ROOM_PRICE_SUCCESS:
      return {
        ...state,
        isPricesFetching: false,
        pricesData: action.payload,
      };
    case EventRoomPriceTypes.FETCH_EVENT_ROOM_PRICE_FAIL:
      return {
        ...state,
        isPricesFetching: false,
        pricesErrorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default eventRoomReducer;
