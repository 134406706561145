import PAYMENT_TERMINAL_TYPES from "./payment-terminal.types";

export const setPaymentTerminalMode = (mode) => ({
  type: PAYMENT_TERMINAL_TYPES.SET_PAYMENT_TERMINAL_MODE,
  payload: mode,
});

export const resetPaymentTerminalMode = () => ({
  type: PAYMENT_TERMINAL_TYPES.RESET_PAYMENT_TERMINAL_MODE,
});

export const setPaymentTerminalLoading = (isLoading) => ({
  type: PAYMENT_TERMINAL_TYPES.SET_PAYMENT_TERMINAL_LOADING,
  payload: isLoading,
});
