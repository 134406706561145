import AboutActionTypes from "./about.types";

const INITIAL_STATE = {
  data: [],
  isFetching: true,
  errorMsg: undefined,
};

const aboutReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AboutActionTypes.FETCH_ABOUT_START:
      return {
        ...state,
        isFetching: true,
      };
    case AboutActionTypes.FETCH_ABOUT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
      };
    case AboutActionTypes.FETCH_ABOUT_FAIL:
      return {
        ...state,
        isFetching: false,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default aboutReducer;
