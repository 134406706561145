import { createSelector } from "reselect";

const selectAbout = (state) => state.about;

const selectAboutData = (state) => state.about.data;

export const selectAboutDesc = createSelector(
  [selectAboutData],
  (data) => data.description
);

export const selectMainLogo = createSelector(
  [selectAboutData],
  (data) => data.logo
);

export const selectContactData = createSelector([selectAboutData], (data) => ({
  phone: data.phone,
  latitude: data.latitude,
  longitude: data.longitude,
  email: data.email,
  facebookUrl: data.facebookUrl,
  address: data.address,
}));

export const isAboutFetching = createSelector(
  [selectAbout],
  (data) => data.isFetching
);
