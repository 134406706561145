import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import useGlobalState from "../../global-hook/global-hook-state";
import Axios from "axios";

import EventSpaceBookForm from "../book-form/event-space-book-form.component";
import VirtualOfficeBookForm from "../book-form/virtual-office-book-form.component";
import SkeletonContainer from "../skeleton-container/skeleton-container.component";
import VirtualOfficeExtendBookForm from "../book-form/virtual-office-extend-book-form.component";
import CorporateSecretaryBookForm from "../book-form/corporate-secretary-book-form.component";

import { URL_ENDPOINT } from "../../constants";

import "./book-modal.style.scss";

export const BookModalModes = {
  EVENT_SPACE_MODE: "EVENT_SPACE_MODE",
  VIRTUAL_OFFICE_MODE: "VIRTUAL_OFFICE_MODE",
  VIRTUAL_OFFICE_EXTEND_MODE: "VIRTUAL_OFFICE_EXTEND_MODE",
  CORP_SECRETARY_MODE: "CORP_SECRETARY_MODE",
};

const BookModal = () => {
  const [bookModalMode] = useGlobalState("bookFormMode");
  const [show] = useGlobalState("showBookModal");
  const [title, setTitle] = useState("");
  const [formDetails, setFormDetails] = useState(null);

  const EventSpaceBookFormWithSkeleton = SkeletonContainer(EventSpaceBookForm);
  const VirtualOfficeBookFormWithSkeleton = SkeletonContainer(VirtualOfficeBookForm);
  const VirtualOfficeExtendBookFormWithSkeleton = SkeletonContainer(VirtualOfficeExtendBookForm);

  const onHideDummy = () => {};

  useEffect(() => {
    let urlEndpoint = null;
    switch (bookModalMode) {
      case BookModalModes.EVENT_SPACE_MODE:
        urlEndpoint = URL_ENDPOINT.EVENT_SPACE;
        setTitle("Event Space Booking");
        break;
      case BookModalModes.VIRTUAL_OFFICE_MODE:
        urlEndpoint = URL_ENDPOINT.VIRTUAL_OFFICE;
        setTitle("Virtual Office Application");
        break;
      case BookModalModes.VIRTUAL_OFFICE_EXTEND_MODE:
        urlEndpoint = URL_ENDPOINT.VIRTUAL_OFFICE;
        setTitle("Extend Virtual Office Contract");
        break;
      case BookModalModes.CORP_SECRETARY_MODE:
        urlEndpoint = URL_ENDPOINT.EVENT_SPACE;
        setTitle("Corporate Secretary Application");
        break;
      default:
    }
    if (urlEndpoint) {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };
      Axios.get(process.env.REACT_APP_API_URL + "forms/" + urlEndpoint, requestOptions)
        .then((result) => result.data)
        .then((data) => setFormDetails(data.data))
        .catch((error) => console.log("error", error));
    }
  }, [bookModalMode]);

  return (
    <Modal show={show} scrollable onHide={onHideDummy} size="lg" className="book-modal">
      <Modal.Header closeButton={false}>
        <Modal.Title>{title} Form</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {(() => {
          switch (bookModalMode) {
            case BookModalModes.EVENT_SPACE_MODE:
              return <EventSpaceBookFormWithSkeleton isLoading={formDetails === null} formDetails={formDetails} />;
            case BookModalModes.VIRTUAL_OFFICE_MODE:
              return <VirtualOfficeBookFormWithSkeleton isLoading={formDetails === null} formDetails={formDetails} />;
            case BookModalModes.VIRTUAL_OFFICE_EXTEND_MODE:
              return (
                <VirtualOfficeExtendBookFormWithSkeleton isLoading={formDetails === null} formDetails={formDetails} />
              );

            case BookModalModes.CORP_SECRETARY_MODE:
              return <CorporateSecretaryBookForm />;
            default:
          }
        })()}
      </Modal.Body>
    </Modal>
  );
};

export default BookModal;
