import React, { Fragment } from "react";
import Navigation from "../navigation/navigation.component";
import FooterSection from "../footer-section/footer-section.component";

const SecondaryLayout = ({ children }) => {
  return (
    <Fragment>
      <Navigation />
      <div className="pt-0 pt-lg-150">{children}</div>
      <FooterSection />
    </Fragment>
  );
};

export default SecondaryLayout;
