import React, { Fragment } from "react";
import { Card, Accordion } from "react-bootstrap";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";

import { selectVirtualOfficeFormProps } from "../../redux/virtual-office/virtual-office.selector";
import { setVirtualOfficePropsData } from "../../redux/virtual-office/virtual-office.action";

const BookFormAgreement = ({ formDetails, form, setFormProps, promoCode = true }) => {
  const agreement = form.agreement;

  const importantNotes = formDetails.notes.filter((item) => item.important === 1);

  const setAgreement = (value) => {
    setFormProps({ name: "agreement", data: value });
  };

  const setPromoCode = (e) => {
    const { value } = e.target;
    setFormProps({ name: "promo_code", data: value });
  };
  return (
    <Fragment>
      <div className="row mx-0 mb-50">
        <span className="mb-2">
          <strong>Important Information</strong>
        </span>
        <Accordion className="w-full">
          {formDetails.notes
            .filter((item) => item.important === 1)
            .map((item, i) => (
              <Card key={i}>
                <Card.Header>
                  <Accordion.Toggle eventKey={item.id}>
                    <KeyboardArrowDownRoundedIcon /> {item.title}
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey={item.id}>
                  <Card.Body className="f-12" dangerouslySetInnerHTML={{ __html: item.body }} />
                </Accordion.Collapse>
              </Card>
            ))}
        </Accordion>
      </div>
      <div className="form-row">
        <div className="form-group col-12">
          <div className="custom-control custom-checkbox">
            <div>
              <input
                type="checkbox"
                className="custom-control-input"
                id="agree"
                name="consecutive"
                checked={agreement}
                onChange={(e) => setAgreement(e.target.checked)}
              />
              <label className="custom-control-label" htmlFor="agree">
                I had read the{" "}
                {importantNotes.map((item, i) => {
                  let comma;
                  if (i === importantNotes.length - 1) {
                    comma = "";
                  } else if (i === importantNotes.length - 2) {
                    comma = ", and ";
                  } else {
                    comma = ", ";
                  }
                  return item.title + comma;
                })}
                . I confirmed my agreement to the requirements expected of me as the user of the
                space.
              </label>
            </div>
          </div>
        </div>
        {promoCode && (
          <div className="form-group col-12 col-lg-6">
            <div className="mb-10 f-14 semibold text-uppercase sp-20">Promo Code (Optional)</div>
            <input
              required
              type="text"
              name="promoCode"
              value={!form.promo_code ? "" : form.promo_code}
              onChange={setPromoCode}
              placeholder="Input Your Promo Code Here"
              className="input w-full bg-white border-action-2 focus-action-2 color-heading placeholder-heading"
            />
          </div>
        )}
      </div>
    </Fragment>
  );
};

const mapStateToProps = createStructuredSelector({
  form: selectVirtualOfficeFormProps,
});

const mapDispatchToProps = (dispatch) => ({
  setFormProps: (data) => dispatch(setVirtualOfficePropsData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookFormAgreement);
