import React, { Fragment } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectVirtualOfficeData } from "../../redux/virtual-office/virtual-office.selector";
import { BookModalModes } from "../book-modal/book-modal.component";
import useGlobalState from "../../global-hook/global-hook-state";

const VirtualOfficeContent = ({ virtualOffice }) => {
  const arrLength = virtualOffice.features.length / 2;
  const firstArr = Math.ceil(arrLength);
  const secondArr = Math.floor(arrLength);

  // eslint-disable-next-line no-unused-vars
  const [show, setShow] = useGlobalState("showBookModal");
  // eslint-disable-next-line no-unused-vars
  const [bookFormMode, setBookFormMode] = useGlobalState("bookFormMode");

  const handleBookModal = (e, extend = false) => {
    e.preventDefault();
    setShow(true);
    setBookFormMode(
      extend ? BookModalModes.VIRTUAL_OFFICE_EXTEND_MODE : BookModalModes.VIRTUAL_OFFICE_MODE
    );
  };

  return (
    <Fragment>
      <div className="w-full h-full img-big d-flex align-items-bottom justify-content-center">
        <img
          src={virtualOffice.image}
          alt=""
          className="mw-full img align-self-end d-none d-lg-block"
        />
      </div>
      <div className="row justify-content-center text-center">
        <div className="col-xl-10">
          <h2 className="small" data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
            Virtual Office Address
          </h2>
          <div
            className="mt-15 f-30 font-weight-bold action-2 text-adaptive decription d-flex align-items-center justify-content-center"
            data-aos-duration="600"
            data-aos="fade-down"
            data-aos-delay="300"
          >
            ${virtualOffice.price} Per Year
            <button onClick={handleBookModal} className="btn ml-10 lg action-2 f-16">
              Apply Now
            </button>
          </div>
        </div>
        <div
          className="col-xl-10 pt-3 mb-70"
          data-aos-duration="600"
          data-aos="fade-down"
          data-aos-delay="0"
        >
          <span className="color-gray">
            Or click{" "}
            <span href="#" onClick={(e) => handleBookModal(e, true)} className="link color-main">
              here
            </span>{" "}
            to extend your virtual office service with us.
          </span>
        </div>
      </div>
      <div className="row justify-content-center text-center text-lg-left">
        <div
          className="mt-55 col-xl-3 col-lg-4 col-md-6 col-sm-8 order-1 left_side"
          data-aos-duration="600"
          data-aos="fade-down"
          data-aos-delay="300"
        >
          {virtualOffice.features.map(
            (feature, i) =>
              i < firstArr && (
                <div key={i} className="mb-10 ml-50 block">
                  <div
                    className={`mb-10 f-16 ${
                      i % 2 === 0 ? "action-2" : "color-heading"
                    } sp-20 title`}
                  >
                    {feature.description}
                  </div>
                </div>
              )
          )}
        </div>
        <div
          className="d-flex justify-content-center align-items-end col-xl-5 col-lg-4 col-sm-5 col-8 order-4 order-lg-2"
          data-aos-duration="600"
          data-aos="fade-down"
          data-aos-delay="0"
        >
          {" "}
          <img src={virtualOffice.image} alt="" className="mw-full img d-block d-lg-none" />
        </div>
        <div
          className="mt-55 col-xl-3 col-lg-4 col-md-6 col-sm-8 order-3 right_side"
          data-aos-duration="600"
          data-aos="fade-down"
          data-aos-delay="300"
        >
          {virtualOffice.features.map(
            (feature, i) =>
              i > secondArr && (
                <div key={i} className="mb-10 ml-50 block">
                  <div
                    className={`mb-10 f-16 ${
                      i % 2 === 0 ? "action-2" : "color-heading"
                    } sp-20 title`}
                  >
                    {feature.description}
                  </div>
                </div>
              )
          )}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = createStructuredSelector({
  virtualOffice: selectVirtualOfficeData,
});

export default connect(mapStateToProps)(VirtualOfficeContent);
