const INITIAL_STATE = {
  modules: [
    {
      name: "Business 1",
      url: "/",
      image: "business1.png",
      component: "Index",
    },
    {
      name: "Business 2",
      url: "/virtual-office",
      image: "business2.png",
      component: "VirtualOffice",
    },
    {
      name: "Business 3",
      url: "/corporate-secretarial",
      image: "business3.png",
      component: "CorpSecretarial",
    },
  ],
};

const moduleReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default moduleReducer;
