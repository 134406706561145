import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import "./additional-service-form.style.scss";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import {
  fetchAsfStartAsync,
  fetchAsfStart,
  fetchAsfForceStop,
  fetchAsfError,
} from "../../redux/additional-service-form/additional-service-form.actions";
import { Helmet } from "react-helmet";
import AdditionalServiceFormField from "../additional-service-form-field/additional-service-form-field.component";
import { createStructuredSelector } from "reselect";
import {
  selectAsfField,
  selectAsfLoadingStatus,
  selectAsfFieldDataToSend,
  selectAsfErrorMessage,
} from "../../redux/additional-service-form/additional-service-form.selector";
import Axios from "axios";

const AdditionalServiceForm = ({
  fetchForm,
  fieldData,
  fetchLoading,
  fieldDataToSend,
  fetchStart,
  fetchStop,
  setError,
  errorMessage,
}) => {
  const { type, inv } = useParams();
  const [componentElement, setComponentElement] = useState({
    fetchUrlParam: undefined,
    title: "",
  });

  useEffect(() => {
    switch (type) {
      case "vo":
        setComponentElement({
          ...componentElement,
          title: "Virtual Office",
        });
        break;
      case "es":
        setComponentElement({
          ...componentElement,
          title: "Event Space",
        });
        break;
      default:
    }
    fetchForm(type, inv);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setError(undefined);
    fetchStart();
    for (let i = 0; i < fieldDataToSend.length; i++) {
      if (fieldDataToSend[i].value.length > 0) {
        var urlencoded = new URLSearchParams();
        urlencoded.append("field_id", fieldDataToSend[i].field_id);
        urlencoded.append("value", JSON.stringify(fieldDataToSend[i].value));
        urlencoded.append("invoice_number", fieldDataToSend[i].invoice_number);

        try {
          await Axios.post(process.env.REACT_APP_API_URL + "forms/additionalServices", urlencoded);
        } catch (e) {
          fetchStop();
          setError(e.response.data.message);
          break;
        }
      }
    }
    window.location = process.env.REACT_APP_BASE_URL + "thank-you?form=true";
  };

  return (
    <section className="additional-service-form-section">
      <Helmet>
        <title>SI - Additional Service Form - {componentElement.title}</title>
      </Helmet>
      <Container>
        <span
          className="action-1 f-30 semibold"
          data-aos-duration="600"
          data-aos="fade-down"
          data-aos-delay="0"
        >
          Additional Service Form - {componentElement.title}
        </span>
        <form onSubmit={handleFormSubmit}>
          {fetchLoading && <div className="additional-service-form-section__loading-overlay"></div>}
          <Row>
            {fieldData.map((fields, i) => (
              <Col key={i} xs={12} md={8} lg={6}>
                <AdditionalServiceFormField fieldData={fields} />
              </Col>
            ))}
            <Col xs={12} className="d-flex flex-column align-items-center justify-content-center">
              <button
                type="submit"
                className="btn action-2 mb-20 px-50 d-flex semibold justify-content-center align-items-center"
              >
                SUBMIT
              </button>
              {errorMessage && <span className="text-danger">{errorMessage}</span>}
            </Col>
          </Row>
        </form>
      </Container>
    </section>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetchForm: (urlParam, invoiceNumber) => dispatch(fetchAsfStartAsync(urlParam, invoiceNumber)),
  fetchStart: () => dispatch(fetchAsfStart()),
  fetchStop: () => dispatch(fetchAsfForceStop()),
  setError: (errorMessage) => dispatch(fetchAsfError(errorMessage)),
});

const mapStateToProps = createStructuredSelector({
  fieldData: selectAsfField,
  fetchLoading: selectAsfLoadingStatus,
  fieldDataToSend: selectAsfFieldDataToSend,
  errorMessage: selectAsfErrorMessage,
});

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalServiceForm);
