import React from "react";
import { Skeleton } from "@material-ui/lab";

const SectionSkeleton = () => {
  return (
    <div
      className="row justify-content-center w-full"
      data-aos-duration="600"
      data-aos="fade-down"
      data-aos-delay="0"
    >
      <div className="col-6">
        <Skeleton height={60} />
      </div>
      <div className="col-12 mb-5" />
      <div className="col-12">
        <Skeleton height={40} className="mb-2" />
        <Skeleton height={40} className="mb-2" />
        <Skeleton height={40} className="mb-5" />
        <Skeleton height={40} className="mb-2" />
        <Skeleton height={40} className="mb-2" />
        <Skeleton height={40} className="mb-5" />
        <Skeleton height={40} className="mb-2" />
        <Skeleton height={40} className="mb-2" />
        <Skeleton height={40} className="mb-5" />
      </div>
    </div>
  );
};

export default SectionSkeleton;
