const CorpSecretaryTypes = {
  FETCH_CORP_SECRETARY_START: "FETCH_CORP_SECRETARY_START",
  FETCH_CORP_SECRETARY_SUCCESS: "FETCH_CORP_SECRETARY_SUCCESS",
  FETCH_CORP_SECRETARY_FAIL: "FETCH_CORP_SECRETARY_FAIL",
  CORP_SECRETARY_FORM_DATA_UPDATE: "CORP_SECRETARY_FORM_DATA_UPDATE",
  CORP_SECRETARY_FORM_DATA_RESET: "CORP_SECRETARY_FORM_DATA_RESET",
  CORP_SECRETARY_FORM_STEP_NEXT: "CORP_SECRETARY_FORM_STEP_NEXT",
  CORP_SECRETARY_FORM_SET_ERROR: "CORP_SECRETARY_FORM_SET_ERROR",
};

export default CorpSecretaryTypes;
