import { createSelector } from "reselect";

const selectVirtualOffice = (state) => state.virtualOffice;

export const selectVirtualOfficeData = createSelector(
  [selectVirtualOffice],
  (data) => data.virtualOfficeData
);

export const selectVirtualOfficeFetchingStatus = createSelector(
  [selectVirtualOffice],
  (data) => data.isFetching
);

export const selectVirtualOfficeFormProps = createSelector(
  [selectVirtualOffice],
  (data) => data.form
);

export const selectVirtualOfficeFormData = createSelector(
  [selectVirtualOffice],
  (data) => data.form.data
);

export const selectVirtualOfficeFormLoading = createSelector(
  [selectVirtualOffice],
  (data) => data.form.loading
);

export const selectVirtualOfficeResponseData = createSelector(
  [selectVirtualOffice],
  (data) => data.response
);
