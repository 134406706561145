import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import {
  selectVirtualOfficeFormProps,
  selectVirtualOfficeResponseData,
} from "../../redux/virtual-office/virtual-office.selector";
import {
  resetVirtualOfficeFormData,
  resetVirtualOfficeResponseData,
} from "../../redux/virtual-office/virtual-office.action";

import BookFormNavigation from "../book-form-navigation/book-form-navigation.component";
import { BookModalModes } from "../book-modal/book-modal.component";
import VirtualOfficeIdentityForm from "../virtual-office-identity-form/virtual-office-identity-form.component";
import VirtualOfficeCompanyForm from "../virtual-office-company-form/virtual-office-company-form.component";
import BookFormNotes from "../book-form-notes/book-form-notes.component";
import BookFormAgreement from "../book-form-agreement/book-form-agreement.component";
import PaymentTerminal from "../payment-teriminal/payment-teriminal.component";

const VirtualOfficeBookForm = ({ form, formDetails, responseData, resetForm, resetResponse }) => {
  // const dummyData = {
  //   total_pay: 123,
  //   invoice_number: "123",
  //   discount_type: "fix",
  //   total: 200,
  //   contact_person: {
  //     name: "Kevin",
  //     phone: "123",
  //     email: "bintangkevin29@gmail.com",
  //   },
  // };
  const step = form.step;
  const errorMessage = form.errorMessage;

  const [bookingData, setBookingData] = useState(null);
  const [isPayment, setPayment] = useState(false);

  useEffect(() => {
    resetForm();
    resetResponse();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (responseData) {
      setBookingData({
        total_pay: Number(responseData.payments[0].total_price),
        invoice_number: responseData.payments[0].invoice_number,
        discount_type: responseData.payments[0].discount_type,
        total: Number(responseData.payments[0].price),
        contact_person: {
          name: responseData.name,
          phone: responseData.local_contact_number,
          email: responseData.email,
        },
      });
    }
  }, [responseData]);

  useEffect(() => {
    if (step === 4) {
      setPayment(true);
    } else if (step !== 4 && isPayment === true) {
      setPayment(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  return (
    <div className="virtual-office-book-form">
      {(() => {
        switch (step) {
          case 1:
            return <VirtualOfficeIdentityForm />;
          case 2:
            return <VirtualOfficeCompanyForm />;
          case 3:
            return <BookFormAgreement formDetails={formDetails} />;
          case 4:
            return <PaymentTerminal bookingData={bookingData} />;
          default:
        }
      })()}
      {errorMessage !== "" && (
        <div className="row justify-content-center mb-2 text-danger">{errorMessage}</div>
      )}
      <BookFormNavigation
        mode={BookModalModes.VIRTUAL_OFFICE_MODE}
        step={step}
        payment={isPayment}
      />
      <BookFormNotes formDetails={formDetails} />
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  form: selectVirtualOfficeFormProps,
  responseData: selectVirtualOfficeResponseData,
});

const mapDispatchToProps = (dispatch) => ({
  resetForm: () => dispatch(resetVirtualOfficeFormData()),
  resetResponse: () => dispatch(resetVirtualOfficeResponseData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(VirtualOfficeBookForm);
