import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectModules } from "../../redux/module/module.selector";

import "./desktop-navigation.style.scss";
import { Link, useParams } from "react-router-dom";

const DesktopNavigation = ({ modules }) => {
  const { type } = useParams();
  return (
    <header
      className={`bg-dark header_10 d-none d-lg-block si-desktop-nav ${
        type ? "si-desktop-nav--secondary pt-180" : "pb-100 pt-160"
      }`}
    >
      <nav className="header_menu_10 pt-30 pb-30">
        <div className="container mw-970 px-xl-0">
          <div className="row align-items-center medium">
            <div
              className="col-lg-9 d-flex align-items-baseline"
              data-aos-duration="600"
              data-aos="fade-down"
              data-aos-delay="600"
            >
              <div className="logo color-white mr-30">
                <img alt="" src={require("../../assets/i/si_logo_white.svg")} height="120"></img>
              </div>
              {modules.map((module, index) => {
                var dot;
                if (index !== 2) {
                  dot = "●";
                } else {
                  dot = "";
                }
                return (
                  <Link key={index} to={module.url} className="link color-white mr-15">
                    {module.name} <span className="ml-15">{dot}</span>
                  </Link>
                );
              })}
            </div>
            {!type && (
              <div
                className="col-lg-3 d-flex align-items-baseline justify-content-end"
                data-aos-duration="600"
                data-aos="fade-down"
                data-aos-delay="900"
              >
                <a href="#rates" className="btn lg action-1 f-16" style={{color:"black"}}>
                  Book Now
                </a>
              </div>
            )}
          </div>
        </div>
      </nav>
    </header>
  );
};

const mapStateToProps = createStructuredSelector({
  modules: selectModules,
});

export default connect(mapStateToProps)(DesktopNavigation);
