import AboutActionTypes from "./about.types";
import Axios from "axios";

export const fetchAboutStart = () => ({
  type: AboutActionTypes.FETCH_ABOUT_START,
});

export const fetchAboutStartAsync = () => {
  return (dispatch) => {
    dispatch(fetchAboutStart());
    Axios.get(process.env.REACT_APP_API_URL + "aboutUs")
      .then((response) => {
        const receivedData = response.data.data;
        const payloadData = {
          description: receivedData.description,
          logo: receivedData.logo,
          phone: receivedData.telephone,
          latitude: receivedData.maps_lat,
          longitude: receivedData.maps_lng,
          email: receivedData.email,
          facebookUrl: receivedData.facebook,
          address: receivedData.address,
        };
        dispatch(fetchAboutSuccess(payloadData));
      })
      .catch((error) => dispatch(fetchAboutFail(error.message)));
  };
};

export const fetchAboutSuccess = (aboutData) => ({
  type: AboutActionTypes.FETCH_ABOUT_SUCCESS,
  payload: aboutData,
});

export const fetchAboutFail = (errorMsg) => ({
  type: AboutActionTypes.FETCH_ABOUT_FAIL,
  payload: errorMsg,
});
