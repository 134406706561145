import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { isAboutFetching } from "../../redux/about/about.selector";

import Contacts from "../contacts/contacts.component";
import SkeletonContainer from "../skeleton-container/skeleton-container.component";
import ContactForm from "../contact-form/contact-form.component";
//import Refund from "../refund/refund.component";

import "./footer-section.style.scss";

const FooterSection = ({ isAboutFetching }) => {
  const ContactWithSkeleton = SkeletonContainer(Contacts);
  return (
    <section className="pb-100 bg-light contact_7 footer-section">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7446701487106!2d103.8893270153311!3d1.3292363620101841!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da17f4cde60ccf%3A0xce1c6563bdf8b787!2s39%20Ubi%20Rd%201%2C%20Singapore%20408695!5e0!3m2!1sen!2sid!4v1604913889050!5m2!1sen!2sid"
        width="100%"
        height="450"
        frameBorder="0"
        allowFullScreen=""
        aria-hidden="false"
        tabIndex="0"
        title="googleMap"
      ></iframe>
      <div className="container px-xl-0 pt-50">
        <div className="row justify-content-center">
          <div className="col-xl-1"></div>
          <div className="mb-50 mb-md-0 col-xl-4 col-md-5 col-sm-9">
            <h2
              className="small text-center text-lg-left"
              data-aos-duration="600"
              data-aos="fade-down"
              data-aos-delay="0"
            >
              <img alt="" src={require("../../assets/i/footer-form-head.png")}></img>
            </h2>
            <div
              className="mt-20 color-heading text-center text-lg-left"
              data-aos-duration="600"
              data-aos="fade-down"
              data-aos-delay="300"
            >
              We look forward to hearing from you.
            </div>
            <ContactForm />
          </div>
          <div className="col-md-1"></div>
          <div className="col-lg-5 col-md-6 col-sm-9 d-flex flex-column justify-content-between">
            <div>
              <h2
                className="small d-none d-md-block"
                data-aos-duration="600"
                data-aos="fade-down"
                data-aos-delay="300"
              >
                &nbsp;
              </h2>
              <div
                className="mt-30 mb-40 color-heading text-center text-lg-left"
                data-aos-duration="600"
                data-aos="fade-down"
                data-aos-delay="600"
              >
                Alternative you can also reach us with these contact detail:
              </div>
              <ContactWithSkeleton isLoading={isAboutFetching} skeletonRows={3} />
            </div>
            {/* <Refund /> */}
            <div>
              <div
                className="f-14 color-heading"
                data-aos-duration="600"
                data-aos="fade-down"
                data-aos-delay="1200"
              >
                <span> © 2020 Supercharge. All Rights Reserved.</span>
                <span className="d-flex align-items-center footer-logo">
                  Site managed by{" "}
                  <a href="https://supercharge.business/">
                    <img alt="" src={require("../../assets/i/si_logo.svg")} height="35"></img>
                  </a>
                </span>
              </div>
            </div>
          </div>
          <div className="col-xl-1"></div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = createStructuredSelector({
  isAboutFetching: isAboutFetching,
});

export default connect(mapStateToProps)(FooterSection);
