import React, { useState } from "react";
import { createStructuredSelector } from "reselect";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Spinner } from "react-bootstrap";
import { connect } from "react-redux";

import { makeStripePayment } from "../../helpers/payment.helper";

import useGlobalState from "../../global-hook/global-hook-state";

import { selectPaymentTerminalLoadingStatus } from "../../redux/payment-terminal/payment-terminal.selector";

import { setPaymentTerminalLoading } from "../../redux/payment-terminal/payment-terminal.actions";

const StripeCard = ({ bookingData, loading, setLoading }) => {
  const [errMsg, setErrMsg] = useState("");
  const [mode] = useGlobalState("bookFormMode");
  const stripe = useStripe();
  const elements = useElements();

  const contactPerson = bookingData.contact_person;

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();
    setErrMsg("");
    setLoading(true);
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.

      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        email: contactPerson.email,
        phone: contactPerson.phone,
        name: contactPerson.name,
      },
    });

    if (error) {
      console.log("[error]", error);
      setErrMsg(error.message);
      setLoading(false);
    } else {
      const payRes = await makeStripePayment(bookingData.invoice_number, "stripe-card", paymentMethod.id, mode);
      if (payRes.success) {
        window.location = process.env.REACT_APP_BASE_URL + "thank-you";
      } else {
        setErrMsg(payRes.errorMessage);
        setLoading(false);
      }
    }
  };

  return (
    <form className="w-full" onSubmit={handleSubmit}>
      <CardElement
        options={{ hidePostalCode: true }}
        className="input mb-10 py-2 w-full bg-white border-action-2 focus-action-2 color-heading placeholder-heading"
      />
      <div className="d-flex justify-content-center flex-column mb-3">
        <button
          type="submit"
          disabled={!stripe || loading}
          className="btn w-full action-1 px-2 font-weight-bold d-flex align-items-center justify-content-center" style={{ color: '#000E7E'}}
        >
          {loading && (
            <Spinner size="sm" animation="border" className="mr-3" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          )}
          Pay
        </button>
      </div>
      {errMsg !== "" && (
        <div className="d-flex justify-content-center">
          <span className="text-danger">{errMsg}</span>
        </div>
      )}
    </form>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: selectPaymentTerminalLoadingStatus,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (isLoading) => dispatch(setPaymentTerminalLoading(isLoading)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StripeCard);
