import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectCorpSecretaryFetchStatus } from "../../redux/corp-secretary/corp-secretary.selector";

import CorporateSecretarialContent from "../corporate-secretarial-content/corporate-secretarial-content.component";
import SkeletonContainer from "../skeleton-container/skeleton-container.component";
import SkeletonContainerModes from "../skeleton-container/skeleton-container.modes";

const CorporateSecretarialSection = ({ isLoading }) => {
  const CorporateSecretarialContentWithSkeleton = SkeletonContainer(
    CorporateSecretarialContent
  );
  return (
    <section className="pt-40 bg-light content_13">
      <div className="container px-xl-0">
        <div className="row justify-content-center text-center">
          <div className="col-xl-10 col-lg-11">
            <div className="radius10 pt-60 text-center inner">
              <div className="row justify-content-center">
                <CorporateSecretarialContentWithSkeleton
                  isLoading={isLoading}
                  mode={SkeletonContainerModes.SECTION_MODE}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = createStructuredSelector({
  isLoading: selectCorpSecretaryFetchStatus,
});

export default connect(mapStateToProps)(CorporateSecretarialSection);
