import React from "react";

import useGlobalState from "../../global-hook/global-hook-state";

import { BookModalModes } from "../book-modal/book-modal.component";

const EventRoomPricingCard = ({ pricing_data, price }) => {
  //eslint-disable-next-line no-unused-vars
  const [show, setShow] = useGlobalState("showBookModal");
  //eslint-disable-next-line no-unused-vars
  const [bookFormMode, setBookFormMode] = useGlobalState("bookFormMode");

  const handleBookModal = (e) => {
    e.preventDefault();
    setShow(true);
    setBookFormMode(BookModalModes.EVENT_SPACE_MODE);
  };

  return (
    <div className="col-xl-4 col-lg-5 col-md-6 col-sm-9 d-flex align-items-stretch mb-3">
      <div
        className="radius10 pb-50 mb-70 mb-md-0 block d-flex flex-column"
        data-aos-duration="600"
        data-aos="fade-down"
        data-aos-delay="0"
      >
        <img src={pricing_data.image} alt="" className="img radius10 noradius_bottom" />
        <div className="mt-45 px-45 inner h-full d-flex flex-column justify-content-between">
          <div className="d-flex flex-column">
            <div className="mb-20 f-22 d-flex flex-wrap justify-content-between title font-weight-bold">
              <div className="" style={{ color: "#4E4E4E"}}>{pricing_data.title}</div>
              <div className="action-2">S${price}</div>
            </div>
            <div className="color-heading text-adaptive">{pricing_data.description}</div>
          </div>
          <button
            href="#"
            onClick={handleBookModal}
            className="btn mt-35 w-full action-1 px-2 font-weight-bold action-1" style={{ color: '#000E7E'}}
          >
            Book Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default EventRoomPricingCard;
