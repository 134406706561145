import React, { Fragment } from "react";

import VirtualOfficeSection from "../../components/virtual-office-section/virtual-office-section.component";
import { Helmet } from "react-helmet";

const VirtualOfficePage = () => {
  return (
    <Fragment>
      <Helmet>
        <title>SI - Virtual Office</title>
      </Helmet>
      <VirtualOfficeSection />
    </Fragment>
  );
};

export default VirtualOfficePage;
