import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { createStructuredSelector } from "reselect";

import { selectModules } from "../../redux/module/module.selector";

const MobileNavigation = ({ modules }) => {
  return (
    <Fragment>
      <span className="open_menu color-main bg-light radius_full">
        <i className="fas fa-bars lh-40"></i>
      </span>
      <div className="navigation_mobile bg-light type2">
        <span className="close_menu color-main">
          <i className="fas fa-times"></i>
        </span>
        <div className="px-40 pt-60 pb-60 text-center inner">
          {modules.map((module, index) => (
            <div key={index}>
              <Link
                to={module.url}
                className="f-heading f-22 link color-main mb-20"
              >
                {module.name}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = createStructuredSelector({
  modules: selectModules,
});

export default connect(mapStateToProps)(MobileNavigation);
