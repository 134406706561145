import { createSelector } from "reselect";

const selectAsf = (state) => state.additionalServiceForm;

export const selectAsfField = createSelector(
  [selectAsf],
  (state) => state.fields
);

export const selectAsfLoadingStatus = createSelector(
  [selectAsf],
  (state) => state.fetching
);

export const selectAsfFieldDataToSend = createSelector(
  [selectAsf],
  (state) => state.dataToSubmit
);

export const selectAsfErrorMessage = createSelector(
  [selectAsf],
  (state) => state.errorMessage
);
