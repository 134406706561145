import { store } from "../store";
import Axios from "axios";

import { setVirtualOfficeResponseData, setVirtualOfficeFormLoading } from "./virtual-office.action";

export const stepCheckVO = async (data) => {
  const getStateVO = store.getState();
  store.dispatch(setVirtualOfficeFormLoading(true));
  const formData = getStateVO.virtualOffice.form.data;
  const formProps = getStateVO.virtualOffice.form;
  let success = true;
  let errorMessage = "Please fill all of the required fields";
  switch (data) {
    case 1:
      if (
        formData.name === "" ||
        formData.email === "" ||
        formData.nric === "" ||
        formData.nationality === "" ||
        formData.local_contact_number === "" ||
        formData.local_mailing_address === ""
      ) {
        success = false;
      }
      break;
    case 2:
      if (
        formData.start_date === "" ||
        formData.business_name === "" ||
        formData.business_place === "" ||
        formData.business_industry === "" ||
        formData.business_activity === ""
      ) {
        success = false;
      }
      break;
    case 3:
      if (formProps.agreement !== false) {
        const savedBookingData = await saveBookingDataVO();
        if (savedBookingData.success) {
          store.dispatch(setVirtualOfficeResponseData(savedBookingData.data));
          break;
        } else {
          errorMessage = savedBookingData.message;
          success = false;
          break;
        }
      } else {
        errorMessage = "You have to agree with the terms before proceeding";
        success = false;
        break;
      }
    default:
  }
  store.dispatch(setVirtualOfficeFormLoading(false));
  if (success) {
    return { success: true };
  } else {
    return { success: false, payload: errorMessage };
  }
};

const saveBookingDataVO = async () => {
  const getStateVO = store.getState();
  const formData = getStateVO.virtualOffice.form.data;
  const formProps = getStateVO.virtualOffice.form;

  var urlencoded = new URLSearchParams();
  urlencoded.append("name", formData.name);
  urlencoded.append("email", formData.email);
  urlencoded.append("nric", formData.nric);
  urlencoded.append("nationality", formData.nationality);
  urlencoded.append("local_contact_number", formData.local_contact_number);
  urlencoded.append("local_mailing_address", formData.local_mailing_address);
  urlencoded.append("price", "50");
  urlencoded.append("start_date", formData.start_date);
  urlencoded.append("business_number", formData.business_number);
  urlencoded.append("business_name", formData.business_name);
  urlencoded.append("business_place", formData.business_place);
  urlencoded.append("business_industry", formData.business_industry);
  urlencoded.append("business_activity", formData.business_activity);
  urlencoded.append("additional_services", "");
  if (formProps.promo_code !== "") {
    urlencoded.append("promo_code", formProps.promo_code);
  }

  let response;
  let success = true;
  await Axios.post(process.env.REACT_APP_API_URL + "forms/virtualOffice", urlencoded)
    .then((res) => (response = res.data))
    .catch((error) => {
      const res = error.response;
      response = res.data;
      success = false;
    });

  return { ...response, success: success };
};

export const stepCheckEVO = async (step) => {
  const getStateVO = store.getState();
  const responseData = getStateVO.virtualOffice.response;
  const formProps = getStateVO.virtualOffice.form;

  store.dispatch(setVirtualOfficeFormLoading(true));
  let success = true;
  let errorMessage = "Please fill all of the required fields";
  switch (step) {
    case 1:
      if (!responseData) {
        errorMessage = "Please check your invoice number first";
        success = false;
      }
      break;
    case 2:
      if (formProps.agreement === false) {
        errorMessage = "You have to agree with the terms before proceeding";
        success = false;
      }
      break;
    default:
  }
  store.dispatch(setVirtualOfficeFormLoading(false));

  if (success) {
    return { success: true };
  } else {
    return { success: false, payload: errorMessage };
  }
};
