import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import {
  selectCorpSecretaryFormData,
  selectCorpSecretaryFormStep,
  selectCorpSecretaryFormErrorMessage,
} from "../../redux/corp-secretary/corp-secretary.selector";
import {
  setCorpSecretaryFormData,
  setCorpSecretaryStepNext,
  setCorpSecretaryFormErrorMessage,
} from "../../redux/corp-secretary/corp-secretary.action";
import InputTextarea from "../input-textarea/input-textarea.component";
import InputText from "../input-text/input-text.component";

import { fetchCorpSecretaryBooking } from "../book-form/book-form.utils";

const CorporateSecretarialIdentityForm = ({
  setField,
  errorMessage,
  formData,
  setStepNext,
  setError,
}) => {
  const [formLoading, setFormLoading] = useState(false);

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    const payload = {
      name: name,
      value: value,
    };
    setField(payload);
    if (errorMessage !== undefined) {
      setError(undefined);
    }
  };

  const submitBooking = async (e) => {
    e.preventDefault();
    setFormLoading(true);
    const checkFetch = await fetchCorpSecretaryBooking(formData);
    if (checkFetch.success) {
      setStepNext();
      if (errorMessage !== undefined) {
        setError(undefined);
      }
    } else {
      setError(checkFetch.errorMessage);
      setFormLoading(false);
    }
  };

  return (
    <form onSubmit={submitBooking}>
      <div className="form-row mb-20">
        <InputText
          title="Name"
          value={formData.name}
          onChange={handleFieldChange}
          name="name"
          placeholder="Your Name"
        />
        <InputText
          title="Email"
          value={formData.email}
          onChange={handleFieldChange}
          name="email"
          placeholder="Your Email"
          type="email"
        />
        <InputTextarea
          title="Message"
          value={formData.message}
          onChange={handleFieldChange}
          name="message"
          placeholder="Tell us what you need"
        />
        <button
          disabled={formLoading}
          className="btn w-full action-2 px-2 font-weight-bold d-flex align-items-center justify-content-center"
        >
          {formLoading && (
            <Spinner
              size="sm"
              animation="border"
              className="mr-3"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </Spinner>
          )}
          Submit
        </button>
      </div>
    </form>
  );
};

const mapStateToProps = createStructuredSelector({
  formData: selectCorpSecretaryFormData,
  step: selectCorpSecretaryFormStep,
  errorMessage: selectCorpSecretaryFormErrorMessage,
});

const mapDispatchToProps = (dispatch) => ({
  setField: (data) => dispatch(setCorpSecretaryFormData(data)),
  setStepNext: () => dispatch(setCorpSecretaryStepNext()),
  setError: (message) => dispatch(setCorpSecretaryFormErrorMessage(message)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CorporateSecretarialIdentityForm);
