import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectVirtualOfficeResponseData } from "../../redux/virtual-office/virtual-office.selector";

import "./virtual-office-invoice-details.style.scss";
import { Row, Col } from "react-bootstrap";

const VirtualOfficeInvoiceDetails = ({ invoiceData }) => {
  const [invoiceDetails, setInvoiceDetais] = useState([]);

  useEffect(() => {
    if (invoiceData) {
      setInvoiceDetais([
        {
          name: "Invoice Number",
          value: invoiceData.invoice_number,
        },
        {
          name: "Name",
          value: invoiceData.application.name,
        },
        {
          name: "Email",
          value: invoiceData.application.email,
        },
        {
          name: "Start Date",
          value: invoiceData.date_start,
        },
        {
          name: "End Date",
          value: invoiceData.date_expired,
        },
        {
          name: "Status",
          value: invoiceData.status.toUpperCase(),
        },
      ]);
    }
  }, [invoiceData]);
 
  return (
    invoiceData && (
      <div className="virtual-office-invoice-details mb-20">
        <Row className="flex-column justify-content-center align-items-center">
          <Col xs={12} md={8} className="detail-card">
            {invoiceDetails.map((item, i) => (
              <Row key={i}>
                <Col xs={5} className="justify-content-between d-flex">
                  <strong>{item.name}</strong> <span>:</span>
                </Col>
                <Col xs={7}>{item.value}</Col>
              </Row>
            ))}
          </Col>
        </Row>
      </div>
    )
  );
};

const mapStateToProps = createStructuredSelector({
  invoiceData: selectVirtualOfficeResponseData,
});

export default connect(mapStateToProps)(VirtualOfficeInvoiceDetails);
