import React, { Fragment } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectAboutDesc, selectMainLogo } from "../../redux/about/about.selector";

const AboutContent = ({ description, logo }) => {
  return (
    <Fragment>
      <div className="col-12 col-md-6 no-gutters">
        <h2 className="pt-50 pb-45" data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
          <img alt="" src={require("../../assets/i/about.png")}></img>
        </h2>
        <div className="d-flex align-items-center no-gutters pb-50">
          <div className="col-12 f-18 medium text-adaptive">
            <div data-aos-duration="600" data-aos="fade-down" data-aos-delay="150">
              <p dangerouslySetInnerHTML={{ __html: description }} />
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6 d-flex align-items-center justify-content-center">
        <img
          src={logo}
          alt=""
          width="350"
          className="bg img-fluid"
          data-aos-duration="600"
          data-aos="fade-down"
          data-aos-delay="600"
        />
      </div>
    </Fragment>
  );
};

const mapStateToProps = createStructuredSelector({
  description: selectAboutDesc,
  logo: selectMainLogo,
});

export default connect(mapStateToProps)(AboutContent);
