import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectEventRoomPriceFetchingStatus } from "../../redux/event-room/event-room.selector";

import EventRoomPrices from "../event-room-prices/event-room-prices.component";
import SkeletonContainer from "../skeleton-container/skeleton-container.component";
import SkeletonContainerModes from "../skeleton-container/skeleton-container.modes";

const EventRoomPricingSection = ({ isLoading }) => {
  const EventRoomPricesWithSkeleton = SkeletonContainer(EventRoomPrices);

  return (
    <section
      id="rates"
      className="pt-105 pb-100 bg-light pricing_table_6 bg-light pb-100"
    >
      <div className="container px-xl-0">
        <div className="row justify-content-center text-center">
          <div className="col-xl-6 col-lg-8 col-md-10">
            <h2
              className="small action-2"
              data-aos-duration="600"
              data-aos="fade-down"
              data-aos-delay="0"
            >
              Our Rates
            </h2>
            <div
              className="mt-10 mb-40 color-heading text-adaptive"
              data-aos-duration="600"
              data-aos="fade-down"
              data-aos-delay="300"
            >
              Affordability with Convenience
            </div>
          </div>
        </div>
        <EventRoomPricesWithSkeleton
          isLoading={isLoading}
          skeletonRows={2}
          mode={SkeletonContainerModes.CARD_MODE}
        />
      </div>
    </section>
  );
};

const mapStateToProps = createStructuredSelector({
  isLoading: selectEventRoomPriceFetchingStatus,
});

export default connect(mapStateToProps)(EventRoomPricingSection);
