import React from "react";
import { Skeleton } from "@material-ui/lab";

const CardSkeleton = () => {
  return (
    <div
      className="mb-30 col-md-6 event-room-type-card d-flex flex-column"
      data-aos-duration="600"
      data-aos="fade-down"
      data-aos-delay="0"
    >
      <Skeleton height={300} variant="rect" />
      <div className="border-0  w-full pb-30 px-35 radius10 noradius_top block flex-grow-1">
        <div className="color-heading text-adaptive mt-3">
          <Skeleton height={50} width="50%" />
        </div>
        <div className="mt-15 f-14 semibold sp-20 action-2 tags">
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </div>
      </div>
    </div>
  );
};

export default CardSkeleton;
