import React, { useState, Fragment } from "react";
import { Modal, Carousel } from "react-bootstrap";
import ZoomInIcon from "@material-ui/icons/ZoomIn";

import "./event-room-type-card.style.scss";

const EventRoomTypeCard = ({ roomType }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
  };

  return (
    <Fragment>
      <div
        className="event-room-type-card mb-30 col-md-6 event-room-type-card d-flex flex-column"
        data-aos-duration="600"
        data-aos="fade-down"
        data-aos-delay="0"
      >
        <div className="image-container" onClick={handleShow}>
          <img src={roomType.images[0].image} alt="" className="w-full radius10 noradius_bottom" />
          <div className="image-overlay radius10 noradius_bottom">
            <ZoomInIcon />
          </div>
        </div>
        <div className="w-full pb-30 px-35 pt-20 radius10 noradius_top block flex-grow-1">
          <span className="mt-25 mb-20 f-22 font-weight-bold" style={{ color: "#4E4E4E"}}>{roomType.title}</span>
          <div className="color-heading text-adaptive pt-10">{roomType.description}</div>
          <div className="mt-15 f-14 semibold sp-20 action-2 tags">
            <span className="action-2">Suitable for: {roomType.suitable}</span>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Body>
          <Carousel>
            {roomType.images.map((item, i) => (
              <Carousel.Item key={i}>
                <img className="d-block w-full" src={item.image} alt="First slide" />
              </Carousel.Item>
            ))}
          </Carousel>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default EventRoomTypeCard;
