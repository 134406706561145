import React, { useState } from "react";
import { Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";
import { createStructuredSelector } from "reselect";
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";
import { connect } from "react-redux";

import { selectVirtualOfficeFormLoading } from "../../redux/virtual-office/virtual-office.selector";
import { fetchVirtualOfficeInvoiceDataStart } from "../../redux/virtual-office/virtual-office.action";

import InputText from "../input-text/input-text.component";

const VirtualOfficeInvoiceCheck = ({ formLoading, fetchInvoiceData }) => {
  const [invoiceNumber, setInvoiceNumber] = useState("");

  const checkInvoice = (e) => {
    e.preventDefault();
    fetchInvoiceData(invoiceNumber);
  };
  return (
    <form onSubmit={checkInvoice}>
      <div className="form-row justify-content-center align-items-center flex-column mb-20">
        {" "}
        <InputText
          title="Invoice Number"
          name="name"
          placeholder="Your Invoice Number"
          center={true}
          onChange={(e) => setInvoiceNumber(e.target.value)}
          value={invoiceNumber}
        />
        <div className="form-group col-12 col-lg-4 mb-2 d-flex flex-row justify-content-center align-items-center">
          <OverlayTrigger
            key="top"
            placement="top"
            overlay={
              <Tooltip id="tooltip-top">
                You can find your invoice number in your email receipt.
              </Tooltip>
            }
          >
            <InfoRoundedIcon className="mr-4" />
          </OverlayTrigger>
          <button
            disabled={formLoading}
            className="btn w-full action-2 px-2 font-weight-bold d-flex align-items-center justify-content-center"
          >
            {formLoading && (
              <Spinner
                size="sm"
                animation="border"
                className="mr-3"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </Spinner>
            )}
            Check
          </button>
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = createStructuredSelector({
  formLoading: selectVirtualOfficeFormLoading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchInvoiceData: (invoiceNumber) =>
    dispatch(fetchVirtualOfficeInvoiceDataStart(invoiceNumber)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VirtualOfficeInvoiceCheck);
