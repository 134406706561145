import VirtualOfficeTypes from "./virtual-office.types";
import Axios from "axios";
import { stepCheckVO, stepCheckEVO } from "./virtual-office.utils";
import { store } from "../store";

export const fetchVirtualOfficeStart = () => ({
  type: VirtualOfficeTypes.FETCH_VIRTUAL_OFFICE_START,
});

export const fetchVirtualOfficeStartAsync = () => {
  return (dispatch) => {
    dispatch(fetchVirtualOfficeStart());
    Axios.get(process.env.REACT_APP_API_URL + "virtualOffice")
      .then((response) =>
        dispatch(fetchVirtualOfficeSuccess(response.data.data))
      )
      .catch((error) => dispatch(fetchVirtualOfficeFail(error.message)));
  };
};

export const fetchVirtualOfficeSuccess = (data) => ({
  type: VirtualOfficeTypes.FETCH_VIRTUAL_OFFICE_SUCCESS,
  payload: data,
});

export const fetchVirtualOfficeFail = (data) => ({
  type: VirtualOfficeTypes.FETCH_VIRTUAL_OFFICE_FAIL,
  payload: data,
});

export const setVirtualOfficeFormData = (data) => ({
  type: VirtualOfficeTypes.VIRTUAL_OFFICE_FORM_DATA_UPDATE,
  payload: data,
});

export const resetVirtualOfficeFormData = () => ({
  type: VirtualOfficeTypes.VIRTUAL_OFFICE_FORM_DATA_RESET,
});

export const setVirtualOfficePropsData = (data) => ({
  type: VirtualOfficeTypes.VIRTUAL_OFFICE_FORM_PROPS_UPDATE,
  payload: data,
});

export const setVirtualOfficeFormNextStep = (data) => {
  return (dispatch) => {
    stepCheckVO(data).then((res) => {
      if (res.success) {
        dispatch(setVirtualOfficeFormErrorMessage(""));
        dispatch(excecuteVirtualOfficeNextStep());
      } else {
        dispatch(setVirtualOfficeFormErrorMessage(res.payload));
      }
    });
  };
};

export const excecuteVirtualOfficeNextStep = () => ({
  type: VirtualOfficeTypes.VIRTUAL_OFFICE_FORM_STEP_NEXT,
});

export const setVirtualOfficeFormPrevStep = () => {
  store.dispatch(setVirtualOfficeFormErrorMessage(""));
  return {
    type: VirtualOfficeTypes.VIRTUAL_OFFICE_FORM_STEP_PREV,
  };
};

export const setVirtualOfficeFormErrorMessage = (data) => ({
  type: VirtualOfficeTypes.VIRTUAL_OFFICE_FORM_SET_ERROR,
  payload: data,
});

export const setVirtualOfficeResponseData = (data) => ({
  type: VirtualOfficeTypes.VIRTUAL_OFFICE_RESPONSE_DATA_UPDATE,
  payload: data,
});

export const resetVirtualOfficeResponseData = () => ({
  type: VirtualOfficeTypes.VIRTUAL_OFFICE_RESPONSE_DATA_RESET,
});

export const setVirtualOfficeFormLoading = (data) => ({
  type: VirtualOfficeTypes.VIRTUAL_OFFICE_FORM_LOADING_SET,
  payload: data,
});

export const setVirtualOfficeExtendFormNextStep = (data) => {
  return (dispatch) => {
    stepCheckEVO(data).then((res) => {
      if (res.success) {
        dispatch(setVirtualOfficeFormErrorMessage(""));
        dispatch(excecuteVirtualOfficeNextStep());
      } else {
        dispatch(setVirtualOfficeFormErrorMessage(res.payload));
      }
    });
  };
};

export const fetchVirtualOfficeInvoiceDataStart = (invoiceNumber) => {
  return (dispatch) => {
    dispatch(setVirtualOfficeFormLoading(true));
    dispatch(fetchVirtualOfficeInvoiceDataStartAsync(invoiceNumber));
  };
};

export const fetchVirtualOfficeInvoiceDataStartAsync = (invoiceNumber) => {
  return (dispatch) => {
    Axios.get(
      process.env.REACT_APP_API_URL +
        "forms/virtualOffice/renew/" +
        invoiceNumber
    )
      .then((res) => {
        dispatch(setVirtualOfficeResponseData(res.data.data));
        dispatch(setVirtualOfficeFormLoading(false));
        dispatch(setVirtualOfficeFormErrorMessage(""));
      })
      .catch((error) => {
        dispatch(setVirtualOfficeResponseData(null));
        dispatch(setVirtualOfficeFormErrorMessage(error.response.data.message));
        dispatch(setVirtualOfficeFormLoading(false));
      });
  };
};
