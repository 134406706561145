import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  selectCorpSecretaryFormStep,
  selectCorpSecretaryFormErrorMessage,
} from "../../redux/corp-secretary/corp-secretary.selector";
import { resetCorpSecretaryFormData } from "../../redux/corp-secretary/corp-secretary.action";
import BookFormNavigation from "../book-form-navigation/book-form-navigation.component";

import CorporateSecretarialIdentityForm from "../corporate-secretarial-identity-form/corporate-secretarial-identity-form.component";
import CorporateSecretarialSuccessForm from "../corporate-secretarial-success-form/corporate-secretarial-success-form.component";

const CorporateSecretaryBookForm = ({ resetForm, step, errorMessage }) => {
  useEffect(() => {
    resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Fragment>
      {(() => {
        switch (step) {
          case 1:
            return <CorporateSecretarialIdentityForm />;
          case 2:
            return <CorporateSecretarialSuccessForm />;
          default:
        }
      })()}
      {errorMessage !== undefined && (
        <div className="row justify-content-center mb-2 text-danger">{errorMessage}</div>
      )}
      {step === 1 && <BookFormNavigation />}
    </Fragment>
  );
};

const mapStateToProps = createStructuredSelector({
  step: selectCorpSecretaryFormStep,
  errorMessage: selectCorpSecretaryFormErrorMessage,
});

const mapDispatchToProps = (dispatch) => ({
  resetForm: () => dispatch(resetCorpSecretaryFormData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CorporateSecretaryBookForm);
