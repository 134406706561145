import React from "react";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";

import { selectEventRoomTypeFetchingStatus } from "../../redux/event-room/event-room.selector";

import EventRoomTypes from "../event-room-types/event-room-types.component";
import SkeletonContainer from "../skeleton-container/skeleton-container.component";
import SkeletonContainerModes from "../skeleton-container/skeleton-container.modes";

const EventRoomTypeSection = ({ isFetching }) => {
  const EventRoomTypeWithSkeleton = SkeletonContainer(EventRoomTypes);
  return (
    <section className="pt-105 bg-light text-center showcase_9">
      <div className="container px-xl-0">
        <div className="row justify-content-center">
          <div className="col-xl-8 col-lg-10">
            <h2
              className="small action-2"
              data-aos-duration="600"
              data-aos="fade-down"
              data-aos-delay="0"
            >
              Our Space
            </h2>
            <div
              className="mt-15 mb-60 f-22 color-heading text-adaptive"
              data-aos-duration="600"
              data-aos="fade-down"
              data-aos-delay="300"
            >
              We have different configurations to cater for different needs.
            </div>
          </div>
        </div>
        <div className="row align-items-stretch text-sm-left">
          <EventRoomTypeWithSkeleton
            isLoading={isFetching}
            mode={SkeletonContainerModes.CARD_MODE}
            skeletonRows={2}
          />
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = createStructuredSelector({
  isFetching: selectEventRoomTypeFetchingStatus,
});

export default connect(mapStateToProps)(EventRoomTypeSection);
