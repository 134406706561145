import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { isAboutFetching } from "../../redux/about/about.selector";

import "./about-section.style.scss";
import SkeletonContainer from "../skeleton-container/skeleton-container.component";
import AboutContent from "../about-content/about-content.component";
import SkeletonContainerModes from "../skeleton-container/skeleton-container.modes";

const AboutSection = ({ isAboutFetching }) => {
  const AboutContentWithSkeleton = SkeletonContainer(AboutContent);
  return (
    <section className="color-white application_17 about-section">
      <div className="container px-xl-0">
        <div className="row justify-content-start">
          <AboutContentWithSkeleton
            isLoading={isAboutFetching}
            mode={SkeletonContainerModes.SECTION_MODE}
          />
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = createStructuredSelector({
  isAboutFetching: isAboutFetching,
});

export default connect(mapStateToProps)(AboutSection);
