import React from "react";

import "./booking-summary.style.scss";
import { Row, Col } from "react-bootstrap";

const BookingSummary = ({ formData }) => {
  const data = formData;
  const contact = data.contact_person;
  return (
    <div className="booking-summary">
      {data !== [] && contact && (
        <Row>
          <Col xs={12} className="d-flex justify-content-center mb-10">
            <span className="f-20 semibold">Booking Summary</span>
          </Col>
          <Col xs={12} className="d-flex flex-column f-12">
            <span className="color-gray">Client Details</span>
            <span className="semibold f-18">{contact.name}</span>
            <span>{contact.phone}</span>
            {contact.company && <span>{contact.company}</span>}
            <span>{contact.billing_address}</span>
          </Col>
          <Col xs={12}>
            <div className="w-full d-flex details flex-column">
              <div className="w-full d-flex justify-content-between heading f-14">
                <span>Details</span>
                <span>Price</span>
              </div>
              {data.bookings.map((item, i) => (
                <div key={i} className="w-full d-flex justify-content-between f-12">
                  <span>
                    {item.start_date}{" "}
                    {item.start_date === item.end_date ? "" : "to " + item.end_date}(
                    {item.rate_title})
                  </span>
                  <span>S${item.price}</span>
                </div>
              ))}
              {data.discount_type && (
                <div className="w-full d-flex justify-content-between f-12">
                  <span className="action-1">Discount</span>
                  <span className="action-1">
                    {(() => {
                      switch (data.discount_type) {
                        case "fix":
                          return "-S$" + data.discount;
                        case "percentage":
                          return "-" + data.discount + "%";
                        case "free":
                          return "-S$" + data.discount;
                        default:
                      }
                    })()}
                  </span>
                </div>
              )}
            </div>
          </Col>
          <Col xs={12}>
            <div className="w-full d-flex justify-content-between heading f-18 semibold mt-3">
              <span>Total</span>
              <span className="action-2">S${data.total_pay}</span>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default BookingSummary;
