import { createGlobalState } from "react-hooks-global-state";

const initialState = {
  showBookModal: false,
  bookFormMode: "",
};

const { useGlobalState } = createGlobalState(initialState);

export default useGlobalState;
