import React, { Fragment } from "react";

import DesktopNavigation from "../desktop-navigation/desktop-navigation.component";
import MobileNavigation from "../mobile-navigation/mobile-navigation.component";

const Navigation = () => {
  return (
    <Fragment>
      <MobileNavigation />
      <DesktopNavigation />
    </Fragment>
  );
};

export default Navigation;
