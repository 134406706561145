import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import {
  selectVirtualOfficeFormProps,
  selectVirtualOfficeResponseData,
} from "../../redux/virtual-office/virtual-office.selector";

import BookFormNavigation from "../book-form-navigation/book-form-navigation.component";

import BookFormNotes from "../book-form-notes/book-form-notes.component";
import {
  resetVirtualOfficeFormData,
  resetVirtualOfficeResponseData,
} from "../../redux/virtual-office/virtual-office.action";
import VirtualOfficeInvoiceCheck from "../virtual-office-invoice-check/virtual-office-invoice-check.component";
import VirtualOfficeInvoiceDetails from "../virtual-office-invoice-details/virtual-office-invoice-details.component";
import BookFormAgreement from "../book-form-agreement/book-form-agreement.component";
import PaymentTerminal from "../payment-teriminal/payment-teriminal.component";

const VirtualOfficeExtendBookForm = ({
  form,
  formDetails,
  responseData,
  resetForm,
  resetResponse,
}) => {
  const step = form.step;
  const errorMessage = form.errorMessage;

  const [bookingData, setBookingData] = useState(null);
  const [isPayment, setPayment] = useState(false);

  useEffect(() => {
    if (responseData) {
      setBookingData({
        total_pay: Number(responseData.price),
        invoice_number: responseData.invoice_number,
        contact_person: {
          name: responseData.application.name,
          phone: responseData.application.local_contact_number,
          email: responseData.application.email,
        },
      });
    }
  }, [responseData]);

  useEffect(() => {
    if (step === 4) {
      setPayment(true);
    } else if (step !== 4 && isPayment === true) {
      setPayment(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    resetForm();
    resetResponse();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="virtual-office-book-form">
      {(() => {
        switch (step) {
          case 1:
            return (
              <Fragment>
                <VirtualOfficeInvoiceCheck />
                <VirtualOfficeInvoiceDetails />
              </Fragment>
            );
          case 2:
            return <BookFormAgreement formDetails={formDetails} />;
          case 3:
            return <PaymentTerminal bookingData={bookingData} />;
          default:
        }
      })()}
      {errorMessage !== "" && (
        <div className="row justify-content-center mb-2 text-danger">{errorMessage}</div>
      )}
      <BookFormNavigation step={step} payment={isPayment} />
      <BookFormNotes formDetails={formDetails} />
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  form: selectVirtualOfficeFormProps,
  responseData: selectVirtualOfficeResponseData,
});

const mapDispatchToProps = (dispatch) => ({
  resetForm: () => dispatch(resetVirtualOfficeFormData()),
  resetResponse: () => dispatch(resetVirtualOfficeResponseData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(VirtualOfficeExtendBookForm);
