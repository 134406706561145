import ADDITIONAL_SERVICE_FORM_TYPES from "./additional-service-form.types";
import Axios from "axios";

export const fetchAsfStart = () => ({
  type: ADDITIONAL_SERVICE_FORM_TYPES.FETCH_START,
});

export const fetchAsfForceStop = () => ({
  type: ADDITIONAL_SERVICE_FORM_TYPES.FETCH_STOP,
});

export const fetchAsfStartAsync = (type, invoiceNumber) => {
  return (dispatch) => {
    let fetchUrlParams;
    switch (type) {
      case "vo":
        fetchUrlParams = "virtualOffice";
        break;
      case "es":
        fetchUrlParams = "eventSpace";
        break;
      default:
    }
    dispatch(fetchAsfStart());
    Axios.get(process.env.REACT_APP_API_URL + "forms/additionalServices/" + fetchUrlParams)
      .then((res) => {
        const payload = {
          data: res.data.data,
          invoiceNumber: invoiceNumber,
        };
        const data = res.data.data;
        let dataToSubmit = [];
        data.forEach((item, i) => {
          dataToSubmit[i] = {
            field_id: item.id,
            value: item.type === "CHECKBOX" ? [] : "",
            invoice_number: invoiceNumber,
          };
        });
        dispatch(setFieldData(dataToSubmit));
        dispatch(fetchAsfSuccess(payload));
      })
      .catch((error) => dispatch(fetchAsfError(error.response.message)));
  };
};

const fetchAsfSuccess = (data) => ({
  type: ADDITIONAL_SERVICE_FORM_TYPES.FETCH_SUCCESS,
  payload: data,
});

export const fetchAsfError = (errMsg) => ({
  type: ADDITIONAL_SERVICE_FORM_TYPES.FETCH_ERROR,
  payload: errMsg,
});

export const updateFieldData = (data) => ({
  type: ADDITIONAL_SERVICE_FORM_TYPES.UPDATE_FIELD,
  payload: data,
});

export const setFieldData = (data) => ({
  type: ADDITIONAL_SERVICE_FORM_TYPES.SET_FIELD,
  payload: data,
});
