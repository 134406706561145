import { createSelector } from "reselect";

const selectEventRoom = (state) => state.eventRoom;

export const selectEventRoomTypes = createSelector(
  [selectEventRoom],
  (data) => data.typesData
);

export const selectEventRoomPrices = createSelector(
  [selectEventRoom],
  (data) => data.pricesData
);

export const selectEventRoomTypeFetchingStatus = createSelector(
  [selectEventRoom],
  (data) => data.isTypesFetching
);

export const selectEventRoomPriceFetchingStatus = createSelector(
  [selectEventRoom],
  (data) => data.isPriceFetching
);
