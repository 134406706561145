import React from "react";
import ModuleCard from "../module-card/module-card.component";

import "./header.style.scss";

const Header = () => {
  return (
    <div className="px-xl-0 color-white text-center d-flex flex-column justify-content-around h-full header header--max-height">
      <div className="video-container">
        <video autoPlay loop playsInline muted>
          <source src={require("../../assets/v/1.mp4")}></source>
        </video>
      </div>
      <div className="h-full w-full video-overlay bg-dark d-flex justify-content-center"></div>
      <div className="container">
        <div className="row justify-content-center inner">
          <div className="space-word-container h-full">
            {/* <img
              alt=""
              className="align-self-center"
              src={require("../../assets/i/video-overlay.png")}
            ></img> */}
          </div>
          <div
            className="col-lg-12"
            data-aos-duration="600"
            data-aos="fade-down"
            data-aos-delay="0"
          >
            <div
              className="mb-3 logo color-white d-block d-xl-none logo_mobile"
              data-aos-duration="600"
              data-aos="fade-down"
              data-aos-delay="0"
            >
              <img alt="" src={require("../../assets/i/logo-sm.png")}></img>
            </div>
            <h1 className="">YOUR SLOGAN</h1>
          </div>
        </div>
        <div className="mt-70 row justify-content-center text-md-left pb-50">
          <ModuleCard />
        </div>
      </div>
    </div>
  );
};

export default Header;
