import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useStripe } from "@stripe/react-stripe-js";

import { makeStripePayment } from "../../helpers/payment.helper";

import "./payment-loading.style.scss";

const PaymentLoading = () => {
  const [mode, setMode] = useState("");
  const location = useLocation();
  const stripe = useStripe();
  const query = new URLSearchParams(location.search);
  let pollCount = 0;

  useEffect(() => {
    if (mode !== "PaymentConsumed") {
      retrievedSource();
    }
  });

  const retrievedSource = async () => {
    if (query.has("source") && query.has("client_secret")) {
      const sourceId = query.get("source");
      const clientSecret = query.get("client_secret");
      const invoiceNumber = query.get("invoiceNumber");
      setMode("PaymentLoading");
      if (stripe) {
        try {
          const response = await stripe.retrieveSource({
            id: sourceId,
            client_secret: clientSecret,
          });

          const source = response.source;

          switch (source.status) {
            case "chargeable":
              await makeStripePayment(invoiceNumber, "alipay", source.id);
              window.location = process.env.REACT_APP_BASE_URL + "thank-you";
              break;
            case "failed":
              break;
            case "pending":
              if (pollCount < 900) {
                pollCount++;
                return setTimeout(retrievedSource, 1000);
              }
              break;
            case "consumed":
              setMode("PaymentConsumed");
              break;
            default:
              break;
          }
        } catch (e) {
          console.log(e);
        }
      }
    }
  };
  return (
    <div className="payment-loading">
      <Spinner animation="border" size="lg"></Spinner>
      Please wait while we are processing your payment. Please don't close this
      page.
    </div>
  );
};

export default PaymentLoading;
