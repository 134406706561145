import React, { useState, useEffect } from "react";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import DatePicker, { registerLocale } from "react-datepicker";

import "./date-selector.style.scss";

import enGB from "date-fns/locale/en-GB";

import { formatDate } from "../book-form/book-form.utils";

const DateSelector = ({
  rateTimes,
  id,
  roomProp,
  totalBookProp,
  handleBeginDate,
  handleEndDate,
  handleRoomForm,
  handleDeleteBooking,
  individualDateError,
}) => {
  //eslint-disable-next-line no-extend-native
  Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  const [errMsg, setErrMsg] = useState("");

  const beginDateCheck = formatDate(roomProp.beginDate);

  useEffect(() => {
    if (individualDateError.length > 0) {
      const checkIfError = individualDateError.find((item) => item.start_date === beginDateCheck);
      if (checkIfError) {
        setErrMsg(checkIfError.message);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [individualDateError]);

  const dateInit = new Date();

  const minDate = dateInit.addDays(0);
  const maxDate = dateInit.addDays(68);
  registerLocale("enGB", enGB);

  const roomUsage = ["Talks / Seminar", "Events", "Training", "Meeting", "Others"];

  return (
    <div className="form-row justify-content-center date-selector">
      <div className="col-12 d-flex justify-content-between">
        <span className="font-weight-bold f-16 mb-10 text-uppercase">Booking #{id + 1}</span>
        {totalBookProp > 1 && (
          <span>
            <ClearRoundedIcon
              onClick={() => handleDeleteBooking(roomProp.key)}
              className="text-danger link"
            />
          </span>
        )}
      </div>
      <div className={`form-group col-12 ${!roomProp.consecutive ? "col-lg-6" : "col-lg-3"}`}>
        <div className="mb-20 mb-lg-0 input_block">
          <div className="mb-10 f-14 semibold text-uppercase sp-20">
            {roomProp.consecutive && "Start"} Date
          </div>
          <DatePicker
            showWeekNumbers
            dateFormat="yyyy-MM-dd"
            locale={enGB}
            selected={roomProp.beginDate}
            onChange={(date) => handleBeginDate(id, date)}
            minDate={minDate}
            maxDate={maxDate}
            startDate={roomProp.beginDate}
            endDate={roomProp.endDate}
            placeholderText="Select Start Date"
            className="input w-full bg-white border-action-2 focus-action-2 color-heading placeholder-heading"
          />
        </div>
      </div>
      {roomProp.consecutive && (
        <div className="form-group col-12 col-lg-3">
          <div className="mb-20 mb-lg-0 input_block">
            <div className="mb-10 f-14 semibold text-uppercase sp-20">End Date</div>
            <DatePicker
              showWeekNumbers
              dateFormat="yyyy-MM-dd"
              locale={enGB}
              selected={roomProp.endDate}
              onChange={(date) => handleEndDate(id, date)}
              maxDate={maxDate}
              startDate={roomProp.beginDate}
              endDate={roomProp.endDate}
              minDate={roomProp.beginDate}
              placeholderText="Select End Date"
              className="input w-full bg-white border-action-2 focus-action-2 color-heading placeholder-heading"
            />
          </div>
        </div>
      )}
      <div className="form-group col-12 col-lg-6">
        <div className="mb-20 mb-lg-0 input_block">
          <div className="mb-10 f-14 semibold text-uppercase sp-20">Time</div>
          <select
            name="timeId"
            onChange={(e) => handleRoomForm(e, id)}
            className="input w-full bg-white border-action-2 focus-action-2 color-heading placeholder-heading"
            value={roomProp.timeId}
            required
          >
            <option value="">Select Time</option>
            {rateTimes.length > 0 &&
              rateTimes.map((time, i) => {
                return (
                  <option value={time.id} key={i}>
                    {time.begin_time} - {time.end_time} ({time.name})
                  </option>
                );
              })}
          </select>
        </div>
      </div>
      <div className="form-group col-12 col-lg-6">
        <div className="mb-20 mb-lg-0 input_block">
          <div className="mb-10 f-14 semibold text-uppercase sp-20">Number of Expected People</div>
          <input
            required
            value={roomProp.pax}
            onChange={(e) => handleRoomForm(e, id)}
            min="1"
            type="number"
            name="pax"
            placeholder="Expected People"
            className="input w-full bg-white border-action-2 focus-action-2 color-heading placeholder-heading"
          />
        </div>
      </div>
      <div className="form-group col-12 col-lg-6">
        <div className="mb-20 mb-lg-0 input_block">
          <div className="mb-10 f-14 semibold text-uppercase sp-20">Usage of the rooms</div>
          <select
            name="usage"
            className="input w-full bg-white border-action-2 focus-action-2 color-heading placeholder-heading"
            value={roomProp.usage}
            onChange={(e) => handleRoomForm(e, id)}
            required
          >
            <option value="">Select Usage</option>
            {roomUsage.map((usage, i) => (
              <option key={i} value={usage}>
                {usage}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="form-group col-12 mb-0 d-flex">
        <div className="custom-control custom-checkbox">
          <div>
            <input
              type="checkbox"
              className="custom-control-input"
              id={`customCheck${id}`}
              onChange={(e) => handleRoomForm(e, id)}
              name="consecutive"
              checked={Boolean(roomProp.consecutive)}
              value={roomProp.consecutive}
            />
            <label className="custom-control-label" htmlFor={`customCheck${id}`}>
              Book for consecutive days
            </label>
          </div>
        </div>
      </div>
      {errMsg !== "" && (
        <div className="form-row d-flex align-items-center justify-content-center text-danger mt-3">
          {errMsg}
        </div>
      )}
    </div>
  );
};

export default DateSelector;
