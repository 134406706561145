import PAYMENT_TERMINAL_TYPES from "./payment-terminal.types";

const { default: PAYMENT_TERMINAL_MODE } = require("./payment-terminal.modes");

const INITIAL_STATE = {
  mode: PAYMENT_TERMINAL_MODE.DEFAULT_MODE,
  loading: false,
};

const paymentTerminalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PAYMENT_TERMINAL_TYPES.SET_PAYMENT_TERMINAL_MODE:
      return {
        ...state,
        mode: action.payload,
      };
    case PAYMENT_TERMINAL_TYPES.RESET_PAYMENT_TERMINAL_MODE:
      return {
        ...state,
        mode: PAYMENT_TERMINAL_MODE.DEFAULT_MODE,
      };
    case PAYMENT_TERMINAL_TYPES.SET_PAYMENT_TERMINAL_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default paymentTerminalReducer;
