import React, { Fragment, useEffect } from "react";

import Navigation from "../navigation/navigation.component";
import AboutSection from "../about-section/about-section.component";
import FooterSection from "../footer-section/footer-section.component";
import BookModal from "../book-modal/book-modal.component";
import Header from "../header/header.component";
import { withRouter } from "react-router-dom";

const MainLayout = ({ ...props }) => {
  useEffect(() => {
    const unlisten = props.history.listen(() => {
      const elmnt = document.getElementById("content");
      elmnt.scrollIntoView({ behavior: "smooth" });
    });
    return () => {
      unlisten();
    };
  });
  return (
    <Fragment>
      <Navigation />
      <Header />
      <AboutSection />
      <div id="content">{props.children}</div>
      <FooterSection />
      <BookModal />
    </Fragment>
  );
};

export default withRouter(MainLayout);
