export const addFieldData = (fieldDataToAdd, oldFieldData) => {
  const fieldExist = oldFieldData.find(
    (item) => item.field_id === fieldDataToAdd.field_id
  );
  if (fieldExist) {
    return oldFieldData.map((item) =>
      item.field_id === fieldDataToAdd.field_id ? fieldDataToAdd : item
    );
  } else {
    return [...oldFieldData, { ...fieldDataToAdd }];
  }
};
