import { createSelector } from "reselect";

const selectCorpSecretary = (state) => state.corpSecretary;
const selectCorpSecretaryForm = (state) => state.corpSecretary.form;

export const selectCorpSecretaryData = createSelector(
  [selectCorpSecretary],
  (data) => data.corpSecretaryData
);

export const selectCorpSecretaryFetchStatus = createSelector(
  [selectCorpSecretary],
  (data) => data.isFetching
);

export const selectCorpSecretaryFormData = createSelector(
  [selectCorpSecretaryForm],
  (form) => form.data
);

export const selectCorpSecretaryFormStep = createSelector(
  [selectCorpSecretaryForm],
  (data) => data.step
);

export const selectCorpSecretaryFormErrorMessage = createSelector(
  [selectCorpSecretaryForm],
  (data) => data.errorMessage
);
