import React from "react";
import { Helmet } from "react-helmet";

import EventRoomTypeSection from "../../components/event-room-type-section/event-room-type-section.component";
import EventRoomPricingSection from "../../components/event-room-pricing-section/event-room-pricing-section.component";

const IndexPage = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>SI - Welcome</title>
      </Helmet>
      <EventRoomTypeSection />

      <EventRoomPricingSection />
    </React.Fragment>
  );
};

export default IndexPage;
