import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectVirtualOfficeFormData } from "../../redux/virtual-office/virtual-office.selector";
import { setVirtualOfficeFormData } from "../../redux/virtual-office/virtual-office.action";

import InputText from "../input-text/input-text.component";
import SelectionDropdown from "../selection-dropdown/";
import Axios from "axios";

const VirtualOfficeIdentityForm = ({ formData, setField }) => {
  const [countryArray, setCountryArray] = useState([]);
  useEffect(() => {
    let unmounted = false;

    Axios.get("https://restcountries.eu/rest/v2/all").then((res) => {
      if (!unmounted) {
        setCountryArray(res.data);
      }
    });

    return () => {
      unmounted = true;
    };
  }, []);
  const handleFieldChange = (e) => {
    const { name, value } = e.target;

    const payload = {
      name: name,
      data: value,
    };

    setField(payload);
  };

  return (
    <div className="form-row">
      <InputText
        title="Full Name (As in NRIC / Passport)"
        name="name"
        placeholder="Your Name"
        onChange={handleFieldChange}
        value={formData.name}
      />
      <InputText
        title="Email"
        name="email"
        placeholder="Your Email"
        type="email"
        onChange={handleFieldChange}
        value={formData.email}
      />
      <InputText
        title="NRIC / Passport Number (Last 4 Characters)"
        name="nric"
        placeholder="Your NRIC / Passport Number"
        onChange={handleFieldChange}
        value={formData.nric}
      />
      <div className="form-group col-12 col-lg-6 align-items-end d-flex">
        <div className="mb-20 mb-lg-0 input_block align-self-end w-full">
          <div className="mb-10 f-14 semibold text-uppercase sp-20">
            Nationality
          </div>
          <SelectionDropdown
            options={countryArray}
            placeholder="Click to select your nationality"
            name="nationality"
            className="input w-full bg-white border-action-2 focus-action-2 color-heading placeholder-heading"
            optionLabel="name"
            optionValue="name"
            value={formData.nationality}
            onChange={handleFieldChange}
          />
        </div>
      </div>
      <InputText
        title="Local Contact Number"
        name="local_contact_number"
        placeholder="Your Local Contact Number"
        onChange={handleFieldChange}
        value={formData.local_contact_number}
      />
      <InputText
        title="Local Mailing Address"
        name="local_mailing_address"
        placeholder="Your Local Mailing Address"
        type="text"
        onChange={handleFieldChange}
        value={formData.local_mailing_address}
      />
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  formData: selectVirtualOfficeFormData,
});

const mapDispatchToProps = (dispatch) => ({
  setField: (data) => dispatch(setVirtualOfficeFormData(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VirtualOfficeIdentityForm);
