import React, { Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import CreditCardRoundedIcon from "@material-ui/icons/CreditCardRounded";

import PaymentButton from "../payment-button/payment-button.component";
import { connect } from "react-redux";
import { setPaymentTerminalMode } from "../../redux/payment-terminal/payment-terminal.actions";
import PAYMENT_TERMINAL_MODE from "../../redux/payment-terminal/payment-terminal.modes";

const PaymentSelection = ({ setPaymentTerminalMode }) => {
  return (
    <Fragment>
      <Row className="justify-content-center">
        <Col xs={12}>
          <PaymentButton
            variant="action-1"
            onClick={() => setPaymentTerminalMode(PAYMENT_TERMINAL_MODE.CARD_MODE)}
          >
            Pay using Cards <CreditCardRoundedIcon className="ml-3" />
          </PaymentButton>
          {/* <PaymentButton variant="grab" disabled>
            Pay using{" "}
            <img
              alt=""
              height="50%"
              className="ml-3"
              src={require("../../assets/i/grab-pay.png")}
            ></img>
          </PaymentButton>
          <PaymentButton variant="paylah" disabled>
            Pay using{" "}
            <img
              alt=""
              height="70%"
              className="ml-5"
              src={require("../../assets/i/paylah-logo.png")}
            ></img>
          </PaymentButton> */}
        </Col>
      </Row>
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setPaymentTerminalMode: (mode) => dispatch(setPaymentTerminalMode(mode)),
});

export default connect(null, mapDispatchToProps)(PaymentSelection);
