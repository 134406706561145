import Axios from "axios";
import { BookModalModes } from "../components/book-modal/book-modal.component";
import { URL_ENDPOINT } from "../constants";

export const PAYMENT_MODE = {
  PAY: "pay",
  RENEW: "renew",
};

export const makeFreePayment = async (invoiceNumber, urlEndpoint, paymentMode = PAYMENT_MODE.PAY) => {
  var urlencoded = new URLSearchParams();
  urlencoded.append("invoice_number", invoiceNumber);

  let response = { success: true };

  await Axios.post(process.env.REACT_APP_API_URL + "forms/" + urlEndpoint + "/" + paymentMode, urlencoded).catch(
    (error) => (response = { success: false, errorMessage: error.response.data.message })
  );

  return response;
};

export const makeStripePayment = async (invoiceNumber, type, id, formMode) => {
  let selectedMode;
  let paymentMode = PAYMENT_MODE.PAY;
  let response = { success: true };

  switch (formMode) {
    case BookModalModes.VIRTUAL_OFFICE_MODE:
      selectedMode = URL_ENDPOINT.VIRTUAL_OFFICE;
      break;
    case BookModalModes.VIRTUAL_OFFICE_EXTEND_MODE:
      selectedMode = URL_ENDPOINT.VIRTUAL_OFFICE;
      paymentMode = PAYMENT_MODE.RENEW;
      break;
    case BookModalModes.EVENT_SPACE_MODE:
      selectedMode = URL_ENDPOINT.EVENT_SPACE;
      break;
    default:
  }
  var urlencoded = new URLSearchParams();
  urlencoded.append("invoice_number", invoiceNumber);
  urlencoded.append("type", type);
  urlencoded.append("pm_id", id);

  await Axios.post(process.env.REACT_APP_API_URL + "forms/" + selectedMode + "/" + paymentMode, urlencoded).catch(
    (error) => (response = { success: false, errorMessage: error.response.data.message })
  );

  return response;
};

export const makeEventSpaceFreePayment = async (invoiceNumber) => {
  var urlencoded = new URLSearchParams();
  urlencoded.append("invoice_number", invoiceNumber);
  urlencoded.append("type", "free");

  let response;

  await Axios.post(process.env.REACT_APP_API_URL + "forms/eventSpace/pay", urlencoded)
    .then(
      () =>
        (response = {
          success: true,
        })
    )
    .catch(
      (err) =>
        (response = {
          success: false,
          errorMessage: err.response.data.message,
        })
    );

  return response;
};
