import React from "react";
import { connect } from "react-redux";
import { selectCorpSecretaryData } from "../../redux/corp-secretary/corp-secretary.selector";
import { createStructuredSelector } from "reselect";
import useGlobalState from "../../global-hook/global-hook-state";
import { BookModalModes } from "../book-modal/book-modal.component";

const CorporateSecretarialContent = ({ corpSecData }) => {
  //eslint-disable-next-line no-unused-vars
  const [show, setShow] = useGlobalState("showBookModal");
  //eslint-disable-next-line no-unused-vars
  const [bookFormMode, setBookFormMode] = useGlobalState("bookFormMode");

  const handleBookModal = (e) => {
    e.preventDefault();
    setShow(true);
    setBookFormMode(BookModalModes.CORP_SECRETARY_MODE);
  };
  return (
    <div className="col-xl-9 col-lg-10 col-11 text-center">
      <h2 className="mb-15 small" data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
        Corporate Services
      </h2>
      <div
        className="f-18 mb-20 medium color-heading text-adaptive"
        data-aos-duration="600"
        data-aos="fade-down"
        data-aos-delay="300"
      >
        {corpSecData.description}
      </div>
      <button
        href="#"
        onClick={handleBookModal}
        className="btn ml-10 lg action-2 f-16 mb-30"
        data-aos-duration="600"
        data-aos="fade-down"
        data-aos-delay="300"
      >
        Apply Now
      </button>
      <img
        alt=""
        data-aos-duration="600"
        data-aos="fade-down"
        data-aos-delay="300"
        className="img-fluid"
        src={corpSecData.image}
      ></img>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  corpSecData: selectCorpSecretaryData,
});

export default connect(mapStateToProps)(CorporateSecretarialContent);
