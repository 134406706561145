import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import DatePicker, { registerLocale } from "react-datepicker";
import { enGB } from "date-fns/locale";

import { selectVirtualOfficeFormData } from "../../redux/virtual-office/virtual-office.selector";
import { setVirtualOfficeFormData } from "../../redux/virtual-office/virtual-office.action";

import { businessIndustryOption, formatDate } from "../book-form/book-form.utils";

import InputText from "../input-text/input-text.component";
import InputSelect from "../input-select/input-select.component";

const VirtualOfficeCompanyForm = ({ formData, setField }) => {
  const handleFieldChange = (e) => {
    const { name, value } = e.target;

    const payload = {
      name: name,
      data: value,
    };

    setField(payload);
  };

  registerLocale("enGB", enGB);

  return (
    <div className="form-row d-flex justify-content-center align-items-end">
      <div className="form-group col-12 col-lg-6">
        <div className="mb-20 mb-lg-0 input_block">
          <div className="mb-10 f-14 semibold text-uppercase sp-20">
            When do you wish to start the Virtual Office?
          </div>
          <DatePicker
            locale={enGB}
            showWeekNumbers
            dateFormat="yyyy-MM-dd"
            selected={formData.start_date === "" ? null : new Date(formData.start_date)}
            onChange={(date) =>
              handleFieldChange({
                target: { name: "start_date", value: formatDate(date) },
              })
            }
            minDate={new Date()}
            placeholderText="Select Start Date"
            className="input w-full bg-white border-action-2 focus-action-2 color-heading placeholder-heading"
          />
        </div>
      </div>
      <InputText
        title="Business Registration Number"
        name="business_number"
        placeholder="Leave empty if your business is new"
        onChange={handleFieldChange}
        value={formData.business_number}
        required={false}
      />
      <InputText
        title="Name of Business"
        name="business_name"
        placeholder="Your Business Name"
        onChange={handleFieldChange}
        value={formData.business_name}
      />
      <InputText
        title="Place of Business Registration"
        name="business_place"
        placeholder="Your Business' Registration Place"
        onChange={handleFieldChange}
        value={formData.business_place}
      />
      <InputSelect
        title="Place of Business Registration"
        name="business_industry"
        placeholder="Your Business' Registration Place"
        onChange={handleFieldChange}
        value={formData.business_industry}
        options={businessIndustryOption}
      />
      <InputText
        title="Primary Business Activity"
        name="business_activity"
        placeholder="Your Business' Primary Acivity"
        onChange={handleFieldChange}
        value={formData.business_activity}
      />
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  formData: selectVirtualOfficeFormData,
});

const mapDispatchToProps = (dispatch) => ({
  setField: (data) => dispatch(setVirtualOfficeFormData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VirtualOfficeCompanyForm);
