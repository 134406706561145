import EventRoomTypeTypes from "./event-room-type.types";
import Axios from "axios";

export const fetchEventRoomTypeStart = () => ({
  type: EventRoomTypeTypes.FETCH_EVENT_ROOM_TYPE_START,
});

export const fetchEventRoomTypeStartAsync = () => {
  return (dispatch) => {
    dispatch(fetchEventRoomTypeStart());
    Axios.get(process.env.REACT_APP_API_URL + "eventSpace")
      .then((response) =>
        dispatch(fetchEventRoomTypeSuccess(response.data.data))
      )
      .catch((error) => dispatch(fetchEventRoomTypeFail(error.message)));
  };
};

export const fetchEventRoomTypeSuccess = (data) => ({
  type: EventRoomTypeTypes.FETCH_EVENT_ROOM_TYPE_SUCCESS,
  payload: data,
});

export const fetchEventRoomTypeFail = (data) => ({
  type: EventRoomTypeTypes.FETCH_EVENT_ROOM_TYPE_FAIL,
  payload: data,
});
