import CorpSecretaryTypes from "./corp-secretary.types";

const FORM_INITIAL_STATE = {
  data: {
    name: "",
    email: "",
    message: "",
  },
  errorMessage: undefined,
  step: 1,
};

const INITIAL_STATE = {
  corpSecretaryData: [],
  isFetching: true,
  errorMsg: undefined,
  form: FORM_INITIAL_STATE,
};

const corpSecretaryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
    case CorpSecretaryTypes.FETCH_CORP_SECRETARY_START:
      return {
        ...state,
        isFetching: true,
      };
    case CorpSecretaryTypes.FETCH_CORP_SECRETARY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        corpSecretaryData: action.payload,
      };
    case CorpSecretaryTypes.FETCH_CORP_SECRETARY_FAIL:
      return {
        ...state,
        isFetching: false,
        errorMsg: action.payload,
      };
    case CorpSecretaryTypes.CORP_SECRETARY_FORM_DATA_UPDATE:
      return {
        ...state,
        form: {
          ...state.form,
          data: {
            ...state.form.data,
            [action.payload.name]: action.payload.value,
          },
        },
      };
    case CorpSecretaryTypes.CORP_SECRETARY_FORM_DATA_RESET:
      return {
        ...state,
        form: FORM_INITIAL_STATE,
      };
    case CorpSecretaryTypes.CORP_SECRETARY_FORM_STEP_NEXT:
      return {
        ...state,
        form: {
          ...state.form,
          step: state.form.step + 1,
        },
      };
    case CorpSecretaryTypes.CORP_SECRETARY_FORM_SET_ERROR:
      return {
        ...state,
        form: {
          ...state.form,
          errorMessage: action.payload,
        },
      };
  }
};

export default corpSecretaryReducer;
