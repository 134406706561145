import React, { useState, Fragment } from "react";
import Axios from "axios";
import DoneIcon from "@material-ui/icons/Done";
import WarningIcon from "@material-ui/icons/Warning";

import ContactFormModes from "./contact-form.modes";

import "./contact-form.style.scss";

const ContactForm = () => {
  const [formData, setFormData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [mode, setMode] = useState(ContactFormModes.IDLE_MODE);

  const handleInput = (e) => {
    const { value } = e.target;
    const { name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFormSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    await Axios.get("https://api.ipify.org?format=json")
      .then((response) =>
        Axios.get(
          "https://supercharge.business/api/check/" + response.data.ip + "/" + formData.email + ""
        )
          .then((response) => {
            console.log(response);
            if (response.data.status_code === 200) {
              setMode(ContactFormModes.BANNED_MODE);
            } else {
              Axios.post(process.env.REACT_APP_API_URL + "feedback", {
                name: formData.name,
                email: formData.email,
                message: formData.message,
              })
                .then((response) => {
                  if (response.status === 201) {
                    setMode(ContactFormModes.SUCCESS_MODE);
                  }
                })
                .catch(() => setMode(ContactFormModes.ERROR_MODE));
            }
          })
          .catch(() => setMode(ContactFormModes.ERROR_MODE))
      )
      .catch(() => setMode(ContactFormModes.ERROR_MODE));
    setLoading(false);
  };

  return (
    <form
      method="post"
      className="mt-30 contact-form"
      data-aos-duration="600"
      data-aos="fade-down"
      onSubmit={handleFormSubmit}
      data-aos-delay="600"
    >
      {(() => {
        switch (mode) {
          case ContactFormModes.IDLE_MODE:
            return (
              <Fragment>
                <div className="mb-10 f-14 semibold text-uppercase sp-20" style={{ color: '#4E4E4E' }}>E-mail</div>
                <input
                  type="email"
                  name="email"
                  placeholder="Your email address"
                  required="required"
                  onChange={handleInput}
                  disabled={isLoading}
                  className="input mb-35 w-full bg-white border-action-2 focus-action-2 color-heading placeholder-heading"
                />
                <div className="mb-10 f-14 semibold text-uppercase sp-20" style={{ color: '#4E4E4E' }}>Message</div>
                <textarea
                  name="message"
                  placeholder="Your message"
                  required="required"
                  onChange={handleInput}
                  disabled={isLoading}
                  className="input mb-35 w-full radius10 bg-white border-action-2 focus-action-2 color-heading placeholder-heading"
                />
                <div className="d-lg-flex flex-wrap justify-content-between align-items-end">
                  <div className="mb-20 mb-lg-0 input_block">
                    <div className="mb-10 f-14 semibold text-uppercase sp-20" style={{ color: '#4E4E4E' }}>Name</div>
                    <input
                      type="text"
                      name="name"
                      placeholder="Your name"
                      required="required"
                      onChange={handleInput}
                      disabled={isLoading}
                      className="input w-270 bg-white border-action-2 focus-action-2 color-heading placeholder-heading"
                    />
                  </div>

                  <button
                    type="submit"
                    disabled={isLoading}
                    className="btn action-1 px-4 d-flex justify-content-center align-items-center font-weight-bold" style={{ color: "#000E7E"}}
                  >
                    {isLoading ? (
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      "SEND"
                    )}
                  </button>
                </div>
              </Fragment>
            );
          case ContactFormModes.SUCCESS_MODE:
            return (
              <div className="alert alert-primary mt-3" role="alert">
                <DoneIcon /> Your submission is sent. We will get back to you.
              </div>
            );
          case ContactFormModes.BANNED_MODE:
            return (
              <div className="alert alert-danger mt-3" role="alert">
                <WarningIcon /> Your IP/Email is banned by our system.
              </div>
            );
          case ContactFormModes.ERROR_MODE:
            return (
              <div className="alert alert-danger mt-3" role="alert">
                <WarningIcon /> We are having difficulties processing your submission. Please try
                again later.
              </div>
            );
          default:
        }
      })()}
    </form>
  );
};

export default ContactForm;
