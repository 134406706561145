import { combineReducers } from "redux";

import moduleReducer from "./module/module.reducer";
import eventRoomReducer from "./event-room/event-room.reducer";
import aboutReducer from "./about/about.reducer";
import virtualOfficeReducer from "./virtual-office/virtual-office.reducer";
import corpSecretaryReducer from "./corp-secretary/corp-secretary.reducer";
import paymentTerminalReducer from "./payment-terminal/payment-terminal.reducer";
import additionalServiceFormReducer from "./additional-service-form/additional-service-form.reducer";

const rootReducer = combineReducers({
  modules: moduleReducer,
  eventRoom: eventRoomReducer,
  about: aboutReducer,
  virtualOffice: virtualOfficeReducer,
  corpSecretary: corpSecretaryReducer,
  paymentTerminal: paymentTerminalReducer,
  additionalServiceForm: additionalServiceFormReducer,
});

export default rootReducer;
