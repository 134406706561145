import React, { useEffect, Fragment, useState } from "react";
import NavigateNextRoundedIcon from "@material-ui/icons/NavigateNextRounded";
import { Spinner } from "react-bootstrap";
import NavigateBeforeRoundedIcon from "@material-ui/icons/NavigateBeforeRounded";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import useGlobalState from "../../global-hook/global-hook-state";

import {
  setVirtualOfficeFormNextStep,
  setVirtualOfficeFormPrevStep,
  setVirtualOfficeExtendFormNextStep,
} from "../../redux/virtual-office/virtual-office.action";
import { selectVirtualOfficeFormLoading } from "../../redux/virtual-office/virtual-office.selector";
import { selectPaymentTerminalLoadingStatus } from "../../redux/payment-terminal/payment-terminal.selector";

import { BookModalModes } from "../book-modal/book-modal.component";

const BookFormNavigation = ({
  step,
  setNextVO,
  setNextEVO,
  setPrevEVO,
  setPrevVO,
  loadingVO,
  payment,
  paymentTerminalLoading,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [modalShow, setModalShow] = useGlobalState("showBookModal");
  const [mode] = useGlobalState("bookFormMode");
  const handleModalClose = () => setModalShow(false);
  const [showNav, setShowNav] = useState(true);

  useEffect(() => {
    switch (mode) {
      case BookModalModes.CORP_SECRETARY_MODE:
        setShowNav(false);
        break;
      default:
    }
  }, [mode]);

  const handleStep = (stepData, isNext) => {
    switch (mode) {
      case BookModalModes.VIRTUAL_OFFICE_MODE:
        isNext ? setNextVO(stepData) : setPrevVO();
        break;
      case BookModalModes.VIRTUAL_OFFICE_EXTEND_MODE:
        isNext ? setNextEVO(stepData) : setPrevVO();
        break;
      default:
    }
  };

  return (
    <div className="form-row flex-column-reverse flex-lg-row d-flex pt-3">
      <div className="form-group col-12 col-lg-4 mb-2">
        <button
          onClick={handleModalClose}
          className="btn w-full text-danger px-2 font-weight-bold"
          disabled={paymentTerminalLoading}
        >
          Cancel
        </button>
      </div>
      {showNav === true && (
        <Fragment>
          <div className="form-group col-12 col-lg-4 mb-2">
            <button
              onClick={() => handleStep(step, false)}
              className="btn w-full action-2 px-2 font-weight-bold"
              disabled={step === 1 || paymentTerminalLoading}
            >
              <NavigateBeforeRoundedIcon />
              Previous
            </button>
          </div>
          {!payment && (
            <div className="form-group col-12 col-lg-4 mb-2">
              <button
                onClick={() => handleStep(step, true)}
                className="btn w-full action-2 px-2 font-weight-bold d-flex align-items-center justify-content-center"
                disabled={loadingVO || paymentTerminalLoading}
              >
                {loadingVO && (
                  <Spinner size="sm" animation="border" className="mr-3" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                )}
                Next
                <NavigateNextRoundedIcon className="ml-3" />
              </button>
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  loadingVO: selectVirtualOfficeFormLoading,
  paymentTerminalLoading: selectPaymentTerminalLoadingStatus,
});

const mapDispatchToProps = (dispatch) => ({
  setNextVO: (step) => dispatch(setVirtualOfficeFormNextStep(step)),
  setPrevVO: () => dispatch(setVirtualOfficeFormPrevStep()),
  setNextEVO: (step) => dispatch(setVirtualOfficeExtendFormNextStep(step)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookFormNavigation);
