const ADDITIONAL_SERVICE_FORM_TYPES = {
  FETCH_START: "FETCH_ADDITIONAL_SERVICE_FORM_START",
  FETCH_STOP: "FETCH_ADDITIONAL_SERVICE_FORM_STOP",
  FETCH_SUCCESS: "FETCH_ADDITIONAL_SERVICE_FORM_SUCCESS",
  FETCH_ERROR: "FETCH_ADDITIONAL_SERVICE_FORM_ERROR",
  UPDATE_FIELD: "UPDATE_ADDITIONAL_SERVICE_FORM_FIELD_DATA",
  SET_FIELD: "SET_ADDITIONAL_SERVICE_FORM_FIELD_DATA",
  SET_ERROR_MESSAGE: "SET_ADDITIONAL_SERVICE_FORM_FIELD_ERROR_MESSAGE",
};

export default ADDITIONAL_SERVICE_FORM_TYPES;
