import React from "react";
import useGlobalState from "../../global-hook/global-hook-state";

const CorporateSecretarialSuccessForm = () => {
  //eslint-disable-next-line no-unused-vars
  const [show, setShow] = useGlobalState("showBookModal");
  return (
    <div className="d-flex align-items-center justify-content-center flex-column pb-30 pt-30">
      <img alt="" src={require("../../assets/i/thankyou.png")} className="img-fluid mb-20" />
      <span className="color-gray mb-3">
        Thank you for sending your message. We will get back to you soon.
      </span>
      <button
        onClick={() => setShow(false)}
        className="btn action-1 px-5 font-weight-bold d-flex align-items-center justify-content-center" style={{ color: '#000E7E'}}
      >
        Dismiss
      </button>
    </div>
  );
};

export default CorporateSecretarialSuccessForm;
