import React, { Fragment } from "react";
import { createStructuredSelector } from "reselect";
import { selectContactData, isAboutFetching } from "../../redux/about/about.selector";
import { connect } from "react-redux";

const Contacts = ({ contact }) => {
  return (
    <Fragment>
      {" "}
      <div data-aos-duration="600" data-aos="fade-down" data-aos-delay="900">
        <a
          href="tel:+(65) 8835 5911"
          className="mt-10 link color-heading f-17 medium d-flex align-items-baseline"
        >
          <i className="fas fa-phone fa-flip-horizontal w-30 flex-shrink-0 f-17 text-right action-2"></i>
          <div className="flex-grow-1">{contact.phone}</div>
        </a>
        <a
          href="mailto:supercharge@davismw.com"
          className="mt-10 link color-heading f-17 medium d-flex align-items-baseline"
        >
          <i className="fas fa-envelope w-30 action-2 flex-shrink-0 f-17 "></i>
          <div className="flex-grow-1">{contact.email}</div>
        </a>
        <a
          href="https://g.page/co-v-coworking?share"
          className="mt-10 link color-heading f-17 medium d-flex align-items-baseline"
        >
          <i className="fas fa-map-marker-alt w-30 action-2 flex-shrink-0 f-17 "></i>
          <div className="flex-grow-1">{contact.address}</div>
        </a>
      </div>
      <div
        className="mt-50 mb-40 hr w-full h-2 bg-gray"
        data-aos-duration="600"
        data-aos="fade-down"
        data-aos-delay="1200"
      ></div>
      <div
        className="mb-20 f-22 title"
        data-aos-duration="600"
        data-aos="fade-down"
        data-aos-delay="1200"
      >
        <a href={contact.facebookUrl} target="blank" rel="noreferer noopener">
          <img alt="" src={require("../../assets/i/facebook.png")}></img>
        </a>
      </div>
    </Fragment>
  );
};

const mapStateToProps = createStructuredSelector({
  contact: selectContactData,
  isAboutFetching: isAboutFetching,
});

export default connect(mapStateToProps)(Contacts);
