import React from "react";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";

import { selectEventRoomTypes } from "../../redux/event-room/event-room.selector";

import EventRoomTypeCard from "../event-room-type-card/event-room-type-card.component";

const EventRoomTypes = ({ roomTypes }) => {
  return roomTypes.map((roomType, index) =>
    roomType ? <EventRoomTypeCard key={index} roomType={roomType} /> : "Loading"
  );
};

const mapStateToProps = createStructuredSelector({
  roomTypes: selectEventRoomTypes,
});

export default connect(mapStateToProps)(EventRoomTypes);
