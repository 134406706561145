const PAYMENT_TERMINAL_MODE = {
  WECHAT_MODE: "WECHAT_MODE",
  CARD_MODE: "CARD_MODE",
  DEFAULT_MODE: "DEFAULT_MODE",
  GRABPAY_MODE: "GRABPAY_MODE",
  PAYLAH_MODE: "PAYLAH_MODE",
  FREE_MODE: "FREE_MODE",
};

export default PAYMENT_TERMINAL_MODE;
