import React, { useEffect, useState } from "react";

import "./thank-you.style.scss";
import { Container } from "react-bootstrap";
import queryString from "query-string";

const ThankYouPage = () => {
  const [formMode, setFormMode] = useState(false);
  useEffect(() => {
    let urlSearch = window.location.search;
    let params = queryString.parse(urlSearch);
    const { form } = params;
    if (form) {
      setFormMode(true);
    }
    setTimeout(() => {
      window.location = process.env.REACT_APP_BASE_URL;
    }, 10000);
  }, []);

  return (
    <div className="thank-you-page">
      <Container>
        <img src={require("../../assets/i/thankyou.png")} alt="" className="img-fluid logo mb-30" />
        <span className="text-center">
          {formMode
            ? `Thank you for filling up the form.`
            : `Thank you for finishing your payment. We will get back to you shortly. Please check your spam if you don't receive any email from us.`}
        </span>
        <span className="color-gray f-12">
          You will be redirected to our main page in 10 seconds. If you are not redirected please
          click <a href={process.env.REACT_APP_BASE_URL}>here</a>
        </span>
      </Container>
    </div>
  );
};

export default ThankYouPage;
