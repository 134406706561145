import Axios from "axios";

export const checkAvailability = async (dateArr) => {
  const raw = [];

  dateArr.forEach((item) => {
    const inputtedBeginDate = formatDate(item.beginDate);

    const inputtedEndDate = formatDate(item.endDate);

    const pushedArr = {
      start_date: inputtedBeginDate,
      end_date: inputtedEndDate,
      rate_id: Number(item.idRate),
      rate_time_id: Number(item.timeId),
    };
    raw.push(pushedArr);
  });

  //   console.log(raw);

  let response;

  await Axios.post(process.env.REACT_APP_API_URL + "forms/eventSpace/canBooking", raw)
    .then((res) => {
      response = {
        success: true,
        message: res.data.data.message,
      };
    })
    .catch((error) => {
      console.log(error.response);
      const errResponse = error.response.data;
      response = {
        success: false,
        message: errResponse.message,
        errorDetails: errResponse.data.others,
      };
    });

  return response;
};

export const formatDate = (date) => {
  const yeB = new Intl.DateTimeFormat("en", { year: "numeric" }).format(date);
  const moB = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(date);
  const daB = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
  return yeB + "-" + moB + "-" + daB;
};

export const saveBookingData = async (formData) => {
  let response;
  const roomArr = [];
  formData.rooms.forEach((room) => {
    const pushedArr = {
      usage: room.usage,
      pax: Number(room.pax),
      start_date: formatDate(room.beginDate),
      end_date: formatDate(room.endDate),
      rate_id: Number(room.idRate),
      rate_time_id: Number(room.timeId),
      type_room: "-",
    };
    roomArr.push(pushedArr);
  });

  const raw = JSON.stringify({
    name: formData.name,
    email: formData.email,
    phone: formData.contact,
    billing_address: formData.billingAddress,
    company: formData.company ? formData.company : "",
    promo_code: formData.promoCode ? formData.promoCode : null,
    note: "special request",
    rooms: roomArr,
  });

  // const raw = {
  //   name: "Kevin Samosir",
  //   email: "bintangkevin29@gmail.com",
  //   phone: "1",
  //   billing_address: "1",
  //   company: "Davis Materialworks",
  //   promo_code: null,
  //   note: "special request",
  //   rooms: [
  //     {
  //       usage: "Talks / Seminar",
  //       pax: 1,
  //       start_date: "2020-08-07",
  //       end_date: "2020-08-07",
  //       rate_id: 11,
  //       rate_time_id: 33,
  //       type_room: "-",
  //     },
  //     {
  //       usage: "Other",
  //       pax: 1,
  //       start_date: "2020-08-08",
  //       end_date: "2020-08-09",
  //       rate_id: 12,
  //       rate_time_id: 31,
  //       type_room: "-",
  //     },
  //   ],
  // };

  await Axios.post(process.env.REACT_APP_API_URL + "forms/eventSpace", raw)
    .then((res) => {
      response = {
        success: true,
        data: res.data.data,
      };
      console.log(response);
    })
    .catch((error) => {
      const res = error.response;
      response = {
        success: false,
        errorMessage: res.data.message,
      };
      console.log(response);
    });

  return response;
};

export const fetchCorpSecretaryBooking = async (formData) => {
  var urlencoded = new URLSearchParams();
  urlencoded.append("name", formData.name);
  urlencoded.append("email", formData.email);
  urlencoded.append("message", formData.message);

  let response;

  await Axios.post(process.env.REACT_APP_API_URL + "forms/corporateService", urlencoded)
    .then(() => {
      response = {
        success: true,
      };
    })
    .catch((error) => {
      response = {
        success: false,
        errorMessage: error.response.data.message,
      };
    });

  return response;
};

// export const dummyBookingData = {
//   id: 52,
//   invoice_number: "INV1232254221",
//   total: "440.00",
//   pay_at: null,
//   type: null,
//   success: 0,
//   promo_code: null,
//   discount: null,
//   discount_type: null,
//   event_space_booking_contact_person_id: 68,
//   created_at: "2020-07-30T07:29:50.000000Z",
//   updated_at: "2020-07-30T07:29:50.000000Z",
//   total_pay: "440.00",
//   note: "special request",
//   contact_person: {
//     id: 68,
//     name: "Kevin Samosir",
//     email: "bintangkevin29@gmail.com",
//     phone: "1",
//     company: "Davis Materialworks",
//     billing_address: "1",
//     created_at: "2020-07-30T07:29:50.000000Z",
//     updated_at: "2020-07-30T07:29:50.000000Z",
//   },
//   bookings: [
//     {
//       id: 101,
//       usage: "Talks / Seminar",
//       pax: 1,
//       start_date: "2020-08-07",
//       end_date: "2020-08-07",
//       created_at: "2020-07-30T07:29:50.000000Z",
//       updated_at: "2020-07-30T07:29:50.000000Z",
//       event_space_booking_contact_person_id: 68,
//       begin_time: "14.00",
//       end_time: "18.00",
//       price: "120.00",
//       type_room: "-",
//       details: [
//         {
//           id: 681,
//           date: "2020-08-07",
//           price: "120.00",
//           week_type: "weekdays",
//           event_space_booking_id: 101,
//           created_at: "2020-07-30T07:29:50.000000Z",
//           updated_at: "2020-07-30T07:29:50.000000Z",
//         },
//       ],
//     },
//     {
//       id: 102,
//       usage: "Other",
//       pax: 1,
//       start_date: "2020-08-08",
//       end_date: "2020-08-09",
//       created_at: "2020-07-30T07:29:50.000000Z",
//       updated_at: "2020-07-30T07:29:50.000000Z",
//       event_space_booking_contact_person_id: 68,
//       begin_time: "09.00",
//       end_time: "18.00",
//       price: "320.00",
//       type_room: "-",
//       details: [
//         {
//           id: 682,
//           date: "2020-08-08",
//           price: "160.00",
//           week_type: "weekend",
//           event_space_booking_id: 102,
//           created_at: "2020-07-30T07:29:50.000000Z",
//           updated_at: "2020-07-30T07:29:50.000000Z",
//         },
//         {
//           id: 683,
//           date: "2020-08-09",
//           price: "160.00",
//           week_type: "weekend",
//           event_space_booking_id: 102,
//           created_at: "2020-07-30T07:29:50.000000Z",
//           updated_at: "2020-07-30T07:29:50.000000Z",
//         },
//       ],
//     },
//   ],
// };

export const businessIndustryOption = [
  {
    name: "F&B",
    value: "F&B",
  },
  {
    name: "Retail",
    value: "Retail",
  },
  {
    name: "Logistics / Supply Chain",
    value: "Logistics / Supply Chain",
  },
  {
    name: "Transport",
    value: "Transport",
  },
  {
    name: "Legal",
    value: "Legal",
  },
  {
    name: "Accountancy",
    value: "Accountancy",
  },
  {
    name: "HR",
    value: "HR",
  },
  {
    name: "Manufacturing/Engineering",
    value: "Manufacturing/Engineering",
  },
  {
    name: "Construction",
    value: "Construction",
  },
  {
    name: "Education/Training",
    value: "Education/Training",
  },
  {
    name: "Healthcare",
    value: "Healthcare",
  },
  {
    name: "Resources Management/Energy",
    value: "Resources Management/Energy",
  },
  {
    name: "Travel/Hospitality",
    value: "Travel/Hospitality",
  },
  {
    name: "Marketing/Advertising",
    value: "Marketing/Advertising",
  },
  {
    name: "IT",
    value: "IT",
  },
  {
    name: "Others",
    value: "Others",
  },
];
