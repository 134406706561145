import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import "./selection-dropdown.style.scss";

const SelectionDropdown = ({
  options,
  optionLabel,
  optionValue,
  name = "",
  className = "",
  value,
  onChange,
  placeholder,
}) => {
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [showOption, setShowOption] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const searchInputRef = useRef(null);

  useEffect(() => {
    if (showOption) {
      searchInputRef.current.focus();
    }
  }, [showOption]);

  const handleSearch = (e) => {
    const searchInput = e.target.value;
    setSearchValue(searchInput);
    if (!showOption) {
      setShowOption(true);
    }
    const filteredOption = options.filter((item) =>
      item.name.toLowerCase().includes(searchInput.toLowerCase())
    );
    const matchedOption = options.find(
      (item) => item.name.toLowerCase() === searchInput.toLowerCase()
    );
    if (matchedOption) {
      setShowOption(false);
    }
    if (filteredOption.length > 0) {
      setFilteredOptions(filteredOption);
    }
  };

  const handleOptionClick = (selectedValue) => {
    setShowOption(false);
    const passedValue = {
      target: { name: name, value: selectedValue },
    };
    setSearchValue("");
    onChange(passedValue);
    setFilteredOptions([]);
  };

  return (
    <div className="selection-dropdown">
      <input
        autoComplete="false"
        className={className}
        type="text"
        name={name}
        value={value}
        readOnly
        autoFocus
        placeholder={placeholder}
        onChange={(e) => onChange(e)}
        onClick={() => setShowOption(!showOption)}
      />
      {showOption && (
        <div className="options">
          <div className="inner">
            <div className="searcher">
              <input
                ref={searchInputRef}
                placeholder="Search here..."
                value={searchValue}
                onChange={handleSearch}
              ></input>
            </div>
            {filteredOptions.length > 0 && (
              <div className="list-item">
                {filteredOptions.map((item) => (
                  <div
                    key={item[optionValue]}
                    onClick={() => handleOptionClick(item[optionLabel])}
                    className="item"
                  >
                    {item[optionLabel]}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

SelectionDropdown.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  optionLabel: PropTypes.string,
  optionValue: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};

export default SelectionDropdown;
