import EventRoomPriceTypes from "./event-room-price.types";
import Axios from "axios";

export const fetchEventRoomPriceStart = () => ({
  type: EventRoomPriceTypes.FETCH_EVENT_ROOM_PRICE_START,
});

export const fetchEventRoomPriceStartAsync = () => {
  return (dispatch) => {
    dispatch(fetchEventRoomPriceStart());
    Axios.get(process.env.REACT_APP_API_URL + "eventSpace/rate")
      .then((response) =>
        dispatch(fetchEventRoomPriceSuccess(response.data.data))
      )
      .catch((error) => dispatch(fetchEventRoomPriceFail(error.message)));
  };
};

export const fetchEventRoomPriceSuccess = (data) => ({
  type: EventRoomPriceTypes.FETCH_EVENT_ROOM_PRICE_SUCCESS,
  payload: data,
});

export const fetchEventRoomPriceFail = (data) => ({
  type: EventRoomPriceTypes.FETCH_EVENT_ROOM_PRICE_FAIL,
  payload: data,
});
