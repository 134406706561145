import React from "react";

import "./additional-service-form-field.style.scss";
import InputTextarea from "../input-textarea/input-textarea.component";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import { updateFieldData } from "../../redux/additional-service-form/additional-service-form.actions";
import { selectAsfFieldDataToSend } from "../../redux/additional-service-form/additional-service-form.selector";
import { createStructuredSelector } from "reselect";

const AdditionalServiceFormField = ({ fieldData, updateFieldData, dataToSend }) => {
  const fieldValue = dataToSend.find((item) => item.field_id === fieldData.id);

  const handleFieldChange = (e) => {
    const { value } = e.target;
    let valueToState;
    if (fieldData.type === "CHECKBOX") {
      valueToState = handleCheckboxValue(value, fieldValue.value);
    } else {
      valueToState = value;
    }

    updateFieldData({
      ...fieldValue,
      value: valueToState,
    });
  };

  const handleCheckboxValue = (valueToAdd, oldValue) => {
    const valueExist = oldValue.find((item) => item === valueToAdd);
    if (valueExist) {
      return oldValue.filter((item) => item !== valueToAdd);
    }

    return [...oldValue, valueToAdd];
  };

  return (
    <div className="additional-service-form-field form-group col-12">
      {fieldData.type === "TEXTAREA" ? (
        <InputTextarea
          name={fieldData.id}
          title={fieldData.title}
          required={true}
          placeholder={fieldData.item}
          value={fieldValue.value}
          onChange={handleFieldChange}
        />
      ) : (
        <Col>
          <label className="mb-10 f-14 semibold text-uppercase sp-20" htmlFor={fieldData.id}>
            {fieldData.title}
          </label>
          {fieldData.item.map((option, i) => (
            <div key={i} className={`custom-control custom-${fieldData.type.toLowerCase()}`}>
              <input
                type={fieldData.type.toLowerCase()}
                className="custom-control-input"
                id={fieldData.id + "-" + i}
                name={fieldData.id}
                required={fieldData.type === "CHECKBOX" ? false : true}
                value={option}
                checked={
                  fieldData.type === "CHECKBOX"
                    ? fieldValue.value.find((item) => item === option)
                    : option === fieldValue.value
                }
                onChange={handleFieldChange}
              />
              <label className="custom-control-label" htmlFor={fieldData.id + "-" + i}>
                {option}
              </label>
            </div>
          ))}
        </Col>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateFieldData: (data) => dispatch(updateFieldData(data)),
});

const mapStateToProps = createStructuredSelector({
  dataToSend: selectAsfFieldDataToSend,
});

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalServiceFormField);
