const VirtualOfficeTypes = {
  FETCH_VIRTUAL_OFFICE_START: "FETCH_VIRTUAL_OFFICE_START",
  FETCH_VIRTUAL_OFFICE_SUCCESS: "FETCH_VIRTUAL_OFFICE_SUCCESS",
  FETCH_VIRTUAL_OFFICE_FAIL: "FETCH_VIRTUAL_OFFICE_FAIL",
  VIRTUAL_OFFICE_FORM_DATA_UPDATE: "VIRTUAL_OFFICE_FORM_DATA_UPDATE",
  VIRTUAL_OFFICE_FORM_DATA_RESET: "VIRTUAL_OFFICE_FORM_DATA_RESET",
  VIRTUAL_OFFICE_FORM_PROPS_UPDATE: "VIRTUAL_OFFICE_FORM_PROPS_UPDATE",
  VIRTUAL_OFFICE_FORM_STEP_NEXT: "VIRTUAL_OFFICE_FORM_STEP_NEXT",
  VIRTUAL_OFFICE_FORM_STEP_PREV: "VIRTUAL_OFFICE_FORM_STEP_PREV",
  VIRTUAL_OFFICE_FORM_SET_ERROR: "VIRTUAL_OFFICE_FORM_SET_ERROR",
  VIRTUAL_OFFICE_RESPONSE_DATA_UPDATE: "VIRTUAL_OFFICE_RESPONSE_DATA_UPDATE",
  VIRTUAL_OFFICE_RESPONSE_DATA_RESET: "VIRTUAL_OFFICE_RESPONSE_DATA_RESET",
  VIRTUAL_OFFICE_FORM_LOADING_SET: "VIRTUAL_OFFICE_FORM_LOADING_SET",
};

export default VirtualOfficeTypes;
