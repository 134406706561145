import Axios from "axios";

const { default: CorpSecretaryTypes } = require("./corp-secretary.types");

const fetchCorpSecretaryStart = () => ({
  type: CorpSecretaryTypes.FETCH_CORP_SECRETARY_START,
});

export const fetchCorpSecretaryStartAsync = () => {
  return (dispatch) => {
    dispatch(fetchCorpSecretaryStart());
    Axios.get(process.env.REACT_APP_API_URL + "corporateService")
      .then((response) =>
        dispatch(fetchCorpSecretarySuccess(response.data.data[0]))
      )
      .catch((error) => dispatch(fetchCorpSecretaryFail(error.message)));
  };
};

const fetchCorpSecretarySuccess = (data) => ({
  type: CorpSecretaryTypes.FETCH_CORP_SECRETARY_SUCCESS,
  payload: data,
});

const fetchCorpSecretaryFail = (data) => ({
  type: CorpSecretaryTypes.FETCH_CORP_SECRETARY_FAIL,
  payload: data,
});

export const setCorpSecretaryFormData = (data) => ({
  type: CorpSecretaryTypes.CORP_SECRETARY_FORM_DATA_UPDATE,
  payload: data,
});

export const resetCorpSecretaryFormData = () => ({
  type: CorpSecretaryTypes.CORP_SECRETARY_FORM_DATA_RESET,
});

export const setCorpSecretaryStepNext = () => ({
  type: CorpSecretaryTypes.CORP_SECRETARY_FORM_STEP_NEXT,
});

export const setCorpSecretaryFormErrorMessage = (message) => ({
  type: CorpSecretaryTypes.CORP_SECRETARY_FORM_SET_ERROR,
  payload: message,
});
