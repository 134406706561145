import React, { Fragment } from "react";
import { Helmet } from "react-helmet";

import CorporateSecretarialSection from "../../components/corporate-secretarial-section/corporate-secretarial-section.component";

const CorporateSecretarialPage = () => {
  return (
    <Fragment>
      <Helmet>
        <title>SI - Corporate Services</title>
      </Helmet>
      <CorporateSecretarialSection />
    </Fragment>
  );
};

export default CorporateSecretarialPage;
