import React, { Fragment, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";

import {
  selectPaymentTerminalMode,
  selectPaymentTerminalLoadingStatus,
} from "../../redux/payment-terminal/payment-terminal.selector";
import PAYMENT_TERMINAL_MODE from "../../redux/payment-terminal/payment-terminal.modes";
import {
  resetPaymentTerminalMode,
  setPaymentTerminalMode,
} from "../../redux/payment-terminal/payment-terminal.actions";

import PaymentSelection from "../payment-selection/payment-selection.component";
import FreePayment from "../free-payment/free-payment.component";
import StripeCard from "../stripe-card/stripe-card.component";

import "./payment-teriminal.style.scss";

const PaymentTerminal = ({ bookingData, mode, setPaymentTerminalMode }) => {
  // const handleResetMode = (e) => {
  //   e.preventDefault();
  //   resetMode();
  // };

  useEffect(() => {
    if (bookingData && Number(bookingData.total_pay) === 0) {
      console.log("Jalan");
      setPaymentTerminalMode(PAYMENT_TERMINAL_MODE.FREE_MODE);
    } else {
      console.log("Jalan Juga");
      setPaymentTerminalMode(PAYMENT_TERMINAL_MODE.DEFAULT_MODE);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingData]);

  return (
    bookingData && (
      <Row className="payment-terminal form-row">
        <Col xs={12} className="d-flex align-items-center flex-column mb-10 mb-20">
          {mode === PAYMENT_TERMINAL_MODE.FREE_MODE ? (
            <span className="f-20 bold action-2">No Payment Is Required</span>
          ) : (
            <Fragment>
              <span className="f-20 bold action-2">Payment</span>
              <span className="f-12 color-gray">
                Powered by <a href="https://stripe.com/">stripe</a>
              </span>
            </Fragment>
          )}
        </Col>
        <Col xs={12} className="text-center mb-20">
          <span>
            Amount:{" "}
            {bookingData.discount_type ? (
              <Fragment>
                <del className="color-gray semibold">S${bookingData.total}</del>{" "}
                <strong className="action-2 semibold">S${bookingData.total_pay}</strong>
              </Fragment>
            ) : (
              <span className="action-2 semibold">S${bookingData.total_pay}</span>
            )}
          </span>
        </Col>
        <Col xs={12} lg={6} className="d-flex form-group align-items-center flex-column mb-10">
          {(() => {
            switch (mode) {
              case PAYMENT_TERMINAL_MODE.DEFAULT_MODE:
                return <PaymentSelection />;
              case PAYMENT_TERMINAL_MODE.CARD_MODE:
                return <StripeCard bookingData={bookingData} />;
              case PAYMENT_TERMINAL_MODE.FREE_MODE:
                return <FreePayment invoiceNumber={bookingData.invoice_number} />;
              default:
            }
          })()}
          {
            // {paymentTerimalState.mode === PAYMENT_TERMINAL_MODE.DEFAULT_MODE && (
            //   <AlipayButton
            //     bookingData={bookingData}
            //     isLoading={paymentTerimalState.allLoading}
            //     handleTerminalState={handlePaymentTerminalState}
            //   />
            // )}
            // {(paymentTerimalState.mode === PAYMENT_TERMINAL_MODE.DEFAULT_MODE ||
            //   paymentTerimalState.mode === PAYMENT_TERMINAL_MODE.WECHAT_MODE) && (
            //   <WechatButton
            //     bookingData={bookingData}
            //     isLoading={paymentTerimalState.allLoading}
            //     handleTerminalState={handlePaymentTerminalState}
            //   />
            // )}
          }
          {/* {mode !== PAYMENT_TERMINAL_MODE.DEFAULT_MODE && !isLoading && (
            <span className="link action-1" href="#" onClick={handleResetMode}>
              Choose another payment method
            </span>
          )} */}
        </Col>
      </Row>
    )
  );
};

const mapStateToProps = createStructuredSelector({
  mode: selectPaymentTerminalMode,
  isLoading: selectPaymentTerminalLoadingStatus,
});

const mapDispatchToProps = (dispatch) => ({
  resetMode: () => dispatch(resetPaymentTerminalMode()),
  setPaymentTerminalMode: (mode) => dispatch(setPaymentTerminalMode(mode)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentTerminal);
