import VirtualOfficeTypes from "./virtual-office.types";

export const initStateVO = {
  step: 1,
  errorMessage: "",
  data: {
    name: "",
    email: "",
    nric: "",
    nationality: "",
    local_contact_number: "",
    local_mailing_address: "",
    start_date: "",
    business_number: "",
    business_name: "",
    business_place: "",
    business_industry: "",
    business_activity: "",
    additional_services: "",
  },
  agreement: false,
  promo_code: "",
  loading: false,
};

const INITIAL_STATE = {
  virtualOfficeData: [],
  isFetching: true,
  errorMsg: undefined,
  form: initStateVO,
  response: null,
};

const virtualOfficeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case VirtualOfficeTypes.FETCH_VIRTUAL_OFFICE_START:
      return {
        ...state,
        isFetching: true,
      };
    case VirtualOfficeTypes.FETCH_VIRTUAL_OFFICE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        virtualOfficeData: action.payload,
      };
    case VirtualOfficeTypes.FETCH_VIRTUAL_OFFICE_FAIL:
      return {
        ...state,
        isFetching: false,
        errorMsg: action.payload,
      };
    case VirtualOfficeTypes.VIRTUAL_OFFICE_FORM_DATA_UPDATE:
      return {
        ...state,
        form: {
          ...state.form,
          data: {
            ...state.form.data,
            [action.payload.name]: action.payload.data,
          },
        },
      };
    case VirtualOfficeTypes.VIRTUAL_OFFICE_FORM_PROPS_UPDATE:
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.name]: action.payload.data,
        },
      };
    case VirtualOfficeTypes.VIRTUAL_OFFICE_FORM_STEP_NEXT:
      return {
        ...state,
        form: {
          ...state.form,
          step: state.form.step + 1,
        },
      };
    case VirtualOfficeTypes.VIRTUAL_OFFICE_FORM_SET_ERROR:
      return {
        ...state,
        form: {
          ...state.form,
          errorMessage: action.payload,
        },
      };
    case VirtualOfficeTypes.VIRTUAL_OFFICE_FORM_STEP_PREV:
      return {
        ...state,
        form: {
          ...state.form,
          step: state.form.step - 1,
        },
      };
    case VirtualOfficeTypes.VIRTUAL_OFFICE_FORM_DATA_RESET:
      return {
        ...state,
        form: initStateVO,
      };
    case VirtualOfficeTypes.VIRTUAL_OFFICE_RESPONSE_DATA_UPDATE:
      return {
        ...state,
        response: action.payload,
      };
    case VirtualOfficeTypes.VIRTUAL_OFFICE_RESPONSE_DATA_RESET:
      return {
        ...state,
        response: null,
      };
    case VirtualOfficeTypes.VIRTUAL_OFFICE_FORM_LOADING_SET:
      return {
        ...state,
        form: {
          ...state.form,
          loading: action.payload,
        },
      };
    default:
      return state;
  }
};

export default virtualOfficeReducer;

// const dummyResponse = {
//   id: 1,
//   invoice_number: "inv2026525543",
//   date_start: "2020-10-13",
//   date_expired: "2021-10-13",
//   status: "inactive",
//   price: "50.00",
//   type: null,
//   success: 0,
//   promo_code: null,
//   discount: null,
//   discount_type: null,
//   total_price: "50.00",
//   virtual_office_application_id: 1,
//   created_at: "2020-08-04T06:12:34.000000Z",
//   updated_at: "2020-08-04T06:12:34.000000Z",
//   pay_at: null,
//   application: {
//     id: 1,
//     name: "Muhammad Taufiq Hidayat",
//     email: "mtfiqh@gmail.com",
//     nric: "1234",
//     nationality: "Indonesia",
//     local_contact_number: "+6281273735077",
//     local_mailing_address: "bandar lampung",
//     business_number: "1234",
//     business_name: "abc",
//     business_place: "xyz",
//     business_industry: "IT",
//     business_activity: "selling",
//     additional_services: ["serice 1", "service 2", "service 3"],
//     created_at: "2020-08-04T06:12:34.000000Z",
//     updated_at: "2020-08-04T06:12:34.000000Z",
//   },
// };
