import React from "react";

import "./payment-button.style.scss";

const PaymentButton = ({
  children,
  variant,
  className,
  onClick,
  disabled,
  isLoading,
}) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled || isLoading}
      className={`payment-button semibold d-flex align-items-center justify-content-center btn w-full mb-2 px-5 ${variant} ${className}`} style={{ color: '#000E7E'}}
    >
      {children}
    </button>
  );
};

export default PaymentButton;
