import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectVirtualOfficeFetchingStatus } from "../../redux/virtual-office/virtual-office.selector";

import VirtualOfficeContent from "../virtual-office-content/virtual-office-content.component";
import SkeletonContainer from "../skeleton-container/skeleton-container.component";

import "./virtual-office-section.style.scss";
import SkeletonContainerModes from "../skeleton-container/skeleton-container.modes";

const VirtualOfficeSection = ({ isLoading }) => {
  const VirtualOfficeContentWithSkeleton = SkeletonContainer(
    VirtualOfficeContent
  );
  return (
    <section
      id="rates"
      className="pt-105 bg-light feature_32 virtual-office-section"
    >
      <div className="container px-xl-0">
        <VirtualOfficeContentWithSkeleton
          isLoading={isLoading}
          mode={SkeletonContainerModes.SECTION_MODE}
        />
      </div>
    </section>
  );
};

const mapStateToProps = createStructuredSelector({
  isLoading: selectVirtualOfficeFetchingStatus,
});

export default connect(mapStateToProps)(VirtualOfficeSection);
