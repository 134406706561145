import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { Spinner } from "react-bootstrap";

import { BookModalModes } from "../book-modal/book-modal.component";

import useGlobalState from "../../global-hook/global-hook-state";

import { URL_ENDPOINT } from "../../constants";
import { makeFreePayment } from "../../helpers/payment.helper";
import { createStructuredSelector } from "reselect";
import { selectPaymentTerminalLoadingStatus } from "../../redux/payment-terminal/payment-terminal.selector";
import { setPaymentTerminalLoading } from "../../redux/payment-terminal/payment-terminal.actions";
import { connect } from "react-redux";

const FreePayment = ({ invoiceNumber, loading, setLoading }) => {
  const [bookFormMode] = useGlobalState("bookFormMode");
  const [urlEndpoint, setUrlEndpoint] = useState(undefined);

  const [errorMessage, setErrorMessage] = useState(undefined);

  useEffect(() => {
    if (bookFormMode === BookModalModes.VIRTUAL_OFFICE_MODE) {
      setUrlEndpoint(URL_ENDPOINT.VIRTUAL_OFFICE);
    } else if (bookFormMode === BookModalModes.EVENT_SPACE_MODE) {
      setUrlEndpoint(URL_ENDPOINT.EVENT_SPACE);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleProceedButton = async () => {
    setLoading(true);
    setErrorMessage(undefined);
    const response = await makeFreePayment(invoiceNumber, urlEndpoint);
    if (response.success) {
      window.location.href = `${process.env.REACT_APP_BASE_URL}thank-you`;
    } else {
      setErrorMessage(response.errorMessage);
    }
    setLoading(false);
  };

  return (
    <Fragment>
      {errorMessage && <span className="my-2 text-danger">{errorMessage}</span>}
      <button
        type="submit"
        disabled={!urlEndpoint}
        className="btn w-full action-1 px-2 font-weight-bold d-flex align-items-center justify-content-center"
        onClick={handleProceedButton}
      >
        {loading && (
          <Spinner size="sm" animation="border" className="mr-3" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        )}
        <span className="action-2">Proceed Booking</span>
      </button>
    </Fragment>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: selectPaymentTerminalLoadingStatus,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (status) => dispatch(setPaymentTerminalLoading(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FreePayment);
