import React from "react";

const InputTextarea = ({
  onChange,
  className,
  value,
  placeholder,
  title,
  name,
  required = true,
  type = "text",
  center = false,
}) => {
  return (
    <div className="form-group col-12">
      <div className="mb-20 mb-lg-0 input_block">
        <div
          className={`mb-10 f-14 semibold text-uppercase sp-20 ${
            center && "text-center"
          }`}
        >
          {title}
        </div>
        <textarea
          style={{
            minHeight: 120,
          }}
          required={required}
          type={type}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          rows="10"
          className={`input radius10 w-full bg-white border-action-2 focus-action-2 color-heading placeholder-heading ${className}`}
          value={value}
        />
      </div>
    </div>
  );
};

export default InputTextarea;
